import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useSteps,
} from '@chakra-ui/react';

import css from './index.module.css';

import OnboardingCreateWorkspace from './create-workspace';
import OnboardingInviteTeam from './invite-team';
import OnboardingSyncChats from './sync-chats';

import { useModals } from '@router';

interface StepProps {
    goToNext: () => void;
    goToPrevious: () => void;
    cancel: () => void;
}

interface Step {
    key: string;
    title: string;
    Content: React.ComponentType<StepProps>;
}

const steps: Step[] = [
    {
        key: 'create-workspace',
        title: 'Create your workspace',
        Content: OnboardingCreateWorkspace,
    },
    {
        key: 'invite-team',
        title: 'Invite your team',
        Content: OnboardingInviteTeam,
    },
    {
        key: 'sync-chats',
        title: 'Chats sync',
        Content: OnboardingSyncChats,
    },
];

function CreateWorkspaceNew() {
    const modals = useModals();
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    });

    const currentStep = steps[Math.min(activeStep, steps.length - 1)];

    if (!currentStep) {
        return null; // Or some error UI
    }

    const CurrentStepComponent = currentStep.Content;

    const handleCancel = () => {
        modals.close();
    };

    return (
        <Modal isOpen={true} isCentered={true} onClose={handleCancel}>
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalBody className={css.body}>
                    <CurrentStepComponent
                        key={currentStep.key}
                        goToNext={goToNext}
                        goToPrevious={goToPrevious}
                        cancel={handleCancel}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default CreateWorkspaceNew;
