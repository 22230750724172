import React, { useMemo } from 'react';
import { Flex, Text, Box, Avatar, HStack } from '@chakra-ui/react';
import { InternalMessage } from '../../../../types';
import { getFormattedViewDate } from '../../../../../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../../redux/createStore';
import { ReplyAction } from './ReplyAction';
import { BaseMessageActions } from './BaseMessageActions';
import { MessageBody } from './MessageBody';

interface OwnProps extends InternalMessage {
    isMine: boolean;
    cardId: number;
    workspaceId: number;
    chatTelegramId: number;
    userTelegramId: number;
}

export const Message = React.forwardRef<null, OwnProps>(
    (
        {
            telegramUserId,
            id,
            isMine,
            // senderId,
            senderName,
            sentAt,
            text,
            cardId,
            workspaceId,
            // chatTelegramId,
            taggedUsers,
            userTelegramId,
        },
        ref,
    ) => {
        const avatars = useSelector((state: RootStore) => state.avatars);

        const userAvatar = useMemo(() => {
            if (!telegramUserId) {
                return '';
            }

            const avatar = avatars[`${telegramUserId}`] || '';

            return avatar;
        }, [telegramUserId, avatars]);

        const { time, date } = getFormattedViewDate(sentAt, 'hh:mm A');

        return (
            <Flex
                ref={ref}
                direction={isMine ? 'row-reverse' : 'row'}
                justifyContent="space-between"
                gap="8px"
                alignItems="center"
                _hover={{
                    '.action': {
                        visibility: 'visible',
                        opacity: 1,
                        right: isMine ? '8px' : '-32px',
                        background: 'gray.10',
                    },
                    '.message-body-hovered': {
                        mr: 0,
                    },
                }}
            >
                <HStack
                    spacing="16px"
                    position="relative"
                    overflowX={isMine ? 'hidden' : 'visible'}
                >
                    {!isMine ? (
                        <Avatar boxSize="32px" src={userAvatar} />
                    ) : null}
                    <Box
                        p="12px"
                        className="message-body-hovered"
                        transition="margin 0.4s ease-in-out"
                        background="mainSurface"
                        borderRadius="8px"
                    >
                        {!isMine ? (
                            <Text
                                fontSize="13px"
                                fontWeight="500"
                                color="gray.40"
                            >
                                {senderName}
                            </Text>
                        ) : null}
                        <Text
                            fontSize="15px"
                            whiteSpace="pre-wrap"
                            fontWeight="400"
                            color="primary"
                            pr="64px"
                        >
                            <MessageBody
                                text={text || ''}
                                taggedUsers={taggedUsers || []}
                                userTelegramId={userTelegramId}
                            />
                        </Text>
                    </Box>
                    {!isMine ? (
                        <ReplyAction />
                    ) : (
                        <BaseMessageActions
                            cardId={cardId}
                            workspaceId={workspaceId}
                            messageId={id}
                        />
                    )}
                </HStack>

                <Text
                    color="#919EAB"
                    fontSize="12px"
                    fontWeight="400"
                    flexShrink={0}
                >
                    {time} {date}
                </Text>
            </Flex>
        );
    },
);

// export const Message: React.FC<OwnProps> = ({
// 	text,
// 	isMine,
// 	sentAt,
// 	senderName,
// 	avatarThumbnailUrl,
// 	senderId,
// }) => {
// 	return (
// 		<Flex
// 			direction={isMine ? 'row-reverse' : 'row'}
// 			justifyContent="space-between"
// 			alignItems="center">
// 			<HStack spacing="16px">
// 				{!isMine ? (
// 					<Avatar boxSize="32px" src={avatarThumbnailUrl || ''} />
// 				) : null}
// 				<Box
// 					p="12px"
// 					background={isMine ? 'mainSurface' : 'secondary'}
// 					borderRadius="8px">
// 					{!isMine ? (
// 						<Text fontSize="13px" fontWeight="500" color="white">
// 							{senderName}
// 						</Text>
// 					) : null}
// 					<Text
// 						fontSize="15px"
// 						fontWeight="400"
// 						color={isMine ? 'midnightBlack.10' : 'white'}>
// 						{text}
// 					</Text>
// 				</Box>
// 			</HStack>
// 			<Text color="#919EAB" fontSize="12px" fontWeight="400">
// 				{getFormattedViewDate(sentAt).time}
// 			</Text>
// 		</Flex>
// 	);
// };
