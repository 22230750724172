import ConferenceMode from '@features/ConfMode';

export const Loader = () => 'Route loader';

export const Action = () => 'Route action';

export const Catch = () => <div>Something went wrong...</div>;

export const Pending = () => <div>Loading...</div>;

export default function ConferenceModeIndexPage() {
	return <ConferenceMode />;
}
