/* eslint-disable indent */
import React, { useMemo } from 'react';
import { Box, Button, Flex, Text, VStack, chakra } from '@chakra-ui/react';
import { useSelector as useReduxSelector } from 'react-redux';
import { useTelegram } from '../../../services';
import { useSyncFolders } from '../queries';
import { FolderItem, WarningBannerWrapper } from '../components';
import { EmptyItemsText, Loader } from '../../../components';
import { RootStore } from '../../../redux/createStore';
import { useGetTelegramFolders } from '../../../services/telegram/queries';
import { useTgFolders } from '../../../hooks/useTgFolders';

interface ChatsProps {
    onFinish: VoidFunction;
    includeSkip?: boolean;
}

export const ChatsSync: React.FC<ChatsProps> = ({ onFinish, includeSkip }) => {
    const tg = useTelegram();
    const tgFolders = useTgFolders();
    const { isSynced } = useReduxSelector(
        (state: RootStore) => state.syncState,
    );

    const { data: foldersData, isFetching: isFoldersFetching } =
        useGetTelegramFolders(tg && isSynced);

    const syncFoldersMutation = useSyncFolders({
        onFinish,
    });

    const filteredFromEmptyFolders = useMemo(() => {
        return (
            foldersData?.list.filter((elem) => elem.includedChatIds?.length) ??
            []
        );
    }, [foldersData]);

    const emptyFolderTitles = useMemo(() => {
        return (
            foldersData?.list
                .filter((elem) => {
                    const specialGroup =
                        elem.channels || elem.groups || elem.contacts;

                    const emptyFolder = elem.includedChatIds?.length;
                    return specialGroup && !emptyFolder;
                })
                .map((elem) => elem.title) ?? []
        );
    }, [foldersData]);

    const selectedLength = tgFolders.selected.length;
    return (
        <Flex flexDirection="column" align="center" gap="30px">
            <Box>
                <Text
                    fontWeight="600"
                    fontSize="20px"
                    lineHeight="24px"
                    textAlign="center"
                >
                    Chats sync
                </Text>
                <Text textAlign="center" mt="16px" color="secondary">
                    Sync your Telegram chats with DISE by choosing folders that
                    you want to track.
                </Text>
            </Box>

            {!isFoldersFetching ? (
                filteredFromEmptyFolders.length && foldersData?.list?.length ? (
                    <Flex
                        flexDirection="column"
                        align="start"
                        borderRadius="8px"
                        border="1px solid"
                        borderColor="gray.20"
                        maxH="260px"
                        overflowY="auto"
                        bg="gray.10"
                        w={'100%'}
                    >
                        {foldersData.list
                            .filter((elem) => elem?.includedChatIds.length)
                            .map((folder) => (
                                <FolderItem
                                    name={folder?.title || ''}
                                    value={folder?.id.toString() || ''}
                                    checked={tgFolders.selected.includes(
                                        folder?.id || 0,
                                    )}
                                    key={folder?.id}
                                    onCheckedChange={() =>
                                        tgFolders.toggle(folder?.id || 0)
                                    }
                                />
                            ))}
                    </Flex>
                ) : (
                    <EmptyItemsText
                        text="No folders can be synced now"
                        wrapperProps={{ width: '100%' }}
                    />
                )
            ) : (
                <Loader centerHeight="180px" />
            )}
            <VStack w="full" align="flex-start" spacing={2}>
                {emptyFolderTitles?.length && !isFoldersFetching ? (
                    <WarningBannerWrapper iconName="exclamation-mark-circle">
                        <Text
                            fontSize="11px"
                            color="secondary"
                            fontWeight="400"
                            lineHeight="16px"
                        >
                            <chakra.span fontWeight="500">
                                Only folders where you add chats manually are
                                shown.
                            </chakra.span>{' '}
                            {emptyFolderTitles.length === 1
                                ? `A dynamic folder like ${emptyFolderTitles[0]} can’t be synced due to the Telegram’s restrictions.`
                                : `Dynamic folders like ${emptyFolderTitles.join(', ')} can’t be synced due to the Telegram’s restrictions.`}
                        </Text>
                    </WarningBannerWrapper>
                ) : null}

                <WarningBannerWrapper iconName="info-circle">
                    <Text
                        fontSize="11px"
                        color="secondary"
                        fontWeight="400"
                        lineHeight="16px"
                    >
                        <chakra.span fontWeight="500">
                            We don&apos;t have access to your messages.
                        </chakra.span>{' '}
                        DISE is a Telegram client which displays messages served
                        from Telegram servers and use local cache to enable CRM
                        functionality (like “unanswered”). Only synced chats
                        appear in DISE.
                    </Text>
                </WarningBannerWrapper>
            </VStack>

            <Button
                w="full"
                maxW="200px"
                variant="outline"
                alignSelf="center"
                boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
                onClick={() => syncFoldersMutation.mutate({ silent: false })}
                isDisabled={!!includeSkip && selectedLength === 0}
                isLoading={
                    includeSkip
                        ? selectedLength !== 0 && syncFoldersMutation.isPending
                        : syncFoldersMutation.isPending
                }
            >
                Sync with DISE
            </Button>

            {!includeSkip ? null : (
                <Button
                    onClick={() => syncFoldersMutation.mutate({ silent: true })}
                    w="65%"
                    variant="ghost"
                    alignSelf="center"
                    isLoading={
                        selectedLength === 0 && syncFoldersMutation.isPending
                    }
                >
                    Continue without sync
                </Button>
            )}
        </Flex>
    );
};
