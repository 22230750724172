import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInterval } from 'usehooks-ts';
import { TG_CONFIG } from '../../tg-config';
import { useTelegram } from '../../services';
import {
    useThrottleCallback,
    useGetMatchedCardId,
    useGetCardById,
} from '../../hooks';
import { useLogout } from '../../queries';
import { RootStore } from '../../redux/createStore';
import {
    CardDefaultStatusEnum,
    TGStateAuthenticatedUser,
    TGStateUnauthenticatedUser,
    TGSyncState,
} from '../../types';
import {
    setShowTelegramModal,
    setTelegramConnected,
} from '../../redux/reducers/login-flow.reducer';
import { setSyncState } from '../../redux/reducers/sync-state.reducer';
import { colors } from '../../theme/colors';
import { ErrorPlaceholder } from './error-placeholder';
import { EmptyCardPlaceholder } from './empty-card-placeholder';
import { isEmptyCard } from '../../utils';
import { IframeLockWrapper } from './iframe-lock-wrapper';
import { ArchivedPlaceholder } from './archived-placeholder';
import { useChats } from '../../hooks/useChats';
import { NotInChatPlaceholder } from './not-in-chat-placeholder';
// import { EmptyChatPlaceholder } from './empty-chat-placeholder';
import { Loader } from '../Loader/Loader';

export const TelegramHiddenFrame = () => {
    const tg = useTelegram();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const cardId = useGetMatchedCardId();
    const { mutateAsync: logoutMutation } = useLogout();
    const { token, user } = useSelector((state: RootStore) => state.auth);
    const { showTelegramLoginModal } = useSelector(
        (state: RootStore) => state.loginFlow,
    );

    const isChat = pathname.includes('/chat') && !!user.workSpace;
    const { matchedCard, isLoading: isCardLoading } = useGetCardById(cardId);
    const isTelegramSetting =
        pathname.includes('/telegram-setting') && !!user.workSpace;

    const isTeamChat = useMemo(
        () => user?.workSpace?.teamChat?.cardId === cardId,
        [user, cardId],
    );

    const { accessToken, refreshToken } = token;

    const [statusNotSucceeded, setStatusNotSucceeded] = useState(false);
    const [statusConnected, setStatusConnected] = useState(false);
    const [isIframeLoading, setIsIframeLoading] = useState(true);

    const statusCheckCount = useRef(0);

    const chats = useChats();
    const isEmptyCardChat = useMemo(() => {
        if (!matchedCard) return false;
        return (
            !matchedCard.chatTelegramId ||
            isEmptyCard(matchedCard.chatTelegramId)
        );
    }, [matchedCard]);

    const hasAccess = useMemo(() => {
        if (!matchedCard?.chatTelegramId) return false;
        if (isEmptyCardChat) return false;
        return chats.getChatInfo(matchedCard.chatTelegramId.toString());
    }, [matchedCard, chats, isEmptyCardChat]);

    const isLoading = isIframeLoading || chats.isLoading || isCardLoading;

    // console.log('isLoading', {
    //     isLoading,
    //     isIframeLoading,
    //     chats,
    //     isChat,
    //     matchedCard,
    // });

    const isCardArchived = useMemo(() => {
        if (isTeamChat) return false;
        if (!matchedCard) return true;
        if (!isChat) return false;

        return (
            matchedCard.status?.cardStatusId === CardDefaultStatusEnum.ARCHIVE
        );
    }, [isChat, matchedCard, isTeamChat]);

    const handleAuthStateChange = async (
        state: TGStateUnauthenticatedUser | TGStateAuthenticatedUser,
    ) => {
        if (!state.authed && accessToken && refreshToken) {
            await logoutMutation({
                accessToken,
                refreshToken,
            });

            return;
        }

        if (state.authed && state.userId && showTelegramLoginModal) {
            dispatch(setShowTelegramModal(false));
            dispatch(
                setTelegramConnected({
                    isConnected: true,
                    userId: state.userId,
                }),
            );
        }
    };

    const throttledCallback = useThrottleCallback(handleAuthStateChange, 1);

    useEffect(() => {
        const unsubscribe = tg?.events.subscribe(
            'authStateChanged',
            throttledCallback,
        );

        console.log('authStateChanged-subscribe', unsubscribe);

        return unsubscribe;
    }, [tg?.events, throttledCallback]);

    useEffect(() => {
        const unsubscribe = tg?.events.subscribe(
            'syncStateChanged',
            (state: TGSyncState) => {
                console.log('syncStateChanged', { state });

                dispatch(setSyncState(state.isSynced));
            },
        );
        console.log('authStateChanged-subscribed', unsubscribe);

        return unsubscribe;
    }, [tg?.events]);

    useEffect(() => {
        const unsubscribe = tg?.events.subscribe('loggedOut', async () => {
            console.log('loggedOut');

            if (accessToken && refreshToken) {
                await logoutMutation({
                    accessToken,
                    refreshToken,
                });
            }
        });

        console.log('loggedOut-subscribed', unsubscribe);

        return unsubscribe;
    }, [tg?.events, accessToken, refreshToken]);

    useInterval(
        () => {
            console.log('statusCheckCount', statusCheckCount.current);
            statusCheckCount.current = statusCheckCount.current + 1;

            // @ts-expect-error: Do not rewrite to async
            tg?.status.proxy.check().then((res) => {
                if (res) {
                    setStatusConnected(true);
                }
            });

            if (statusCheckCount.current >= 10) {
                setStatusNotSucceeded(true);
            }
        },
        !statusNotSucceeded && !statusConnected ? 1000 : null,
    );

    const onReload = () => {
        setStatusNotSucceeded(false);
        statusCheckCount.current = 0;
    };

    const getWidth = useCallback(() => {
        switch (true) {
            case showTelegramLoginModal:
                return '400px';
            case !!isChat:
                return '100%';
            case !!isTelegramSetting:
                return '100%';

            default:
                return 0;
        }
    }, [isChat, showTelegramLoginModal, isTelegramSetting]);

    const width = getWidth();

    const iframeUrl = TG_CONFIG.TG_IFRAME_URL;
    //  buildTelegramIframeUrl(
    //     TG_CONFIG.TG_IFRAME_URL,
    //     consts.TEST_BRANCH,
    // );

    console.log('iframeUrl', { iframeUrl });

    const handleIframeLoad = () => {
        setIsIframeLoading(false);
    };

    return (
        <Box
            id="tg-iframe-holder"
            zIndex={10}
            flexShrink={showTelegramLoginModal ? 0 : 1}
            width={width}
            visibility={
                isChat || showTelegramLoginModal || isTelegramSetting
                    ? 'initial'
                    : 'hidden'
            }
            height={showTelegramLoginModal ? '736px' : '100%'}
            position="relative"
        >
            <IframeLockWrapper iframeOpened={width !== 0}>
                <iframe
                    id="telegram-iframe"
                    height="100%"
                    width="100%"
                    allow="clipboard-read; clipboard-write; encrypted-media; gyroscope;"
                    style={{
                        borderRadius: '8px',
                        border: `1px solid ${colors.gray[20]}`,
                    }}
                    src={iframeUrl}
                    referrerPolicy="no-referrer"
                    onLoad={handleIframeLoad}
                />
                {isLoading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                        bg="white"
                        borderRadius="8px"
                        border={`1px solid ${colors.gray[20]}`}
                    >
                        <Loader centerHeight="100%" spinnerSize="xl" />
                    </Box>
                )}
                {statusNotSucceeded && !isLoading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <ErrorPlaceholder onReload={onReload} />
                    </Box>
                )}
                {isChat && isCardArchived && !isLoading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <ArchivedPlaceholder />
                    </Box>
                )}
                {isChat && isEmptyCardChat && !isLoading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <EmptyCardPlaceholder cardId={cardId} />
                    </Box>
                )}
                {isChat && isCardArchived && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <ArchivedPlaceholder />
                    </Box>
                )}
                {isChat && !hasAccess && !isLoading && !isEmptyCardChat && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                    >
                        <NotInChatPlaceholder />
                    </Box>
                )}
                {/* {isChat &&
                    isEmptyChat &&
                    !isLoading &&
                    !isCardArchived &&
                    !isEmptyCardChat && (
                        <Box
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            zIndex={1}
                        >
                            <EmptyChatPlaceholder />
                        </Box>
                    )} */}
                {isLoading && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        zIndex={1}
                        bg="white"
                        borderRadius="8px"
                        border={`1px solid ${colors.gray[20]}`}
                    >
                        <Loader centerHeight="100%" spinnerSize="xl" />
                    </Box>
                )}
            </IframeLockWrapper>
        </Box>
    );
};

const buildTelegramIframeUrl = (iframeUrl: string, branch?: string) => {
    const url = new URL(iframeUrl);
    if (branch) {
        url.searchParams.set('branch', branch);
    }
    return url.toString();
};

const IDENTIFICATED_USER_KEY = 'dise_june_identificated_user_id';
