import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import API from '../api/api.base';
import { ApiResponseEmpty } from '../types';
import { logout } from '../redux/reducers/auth.reducer';
import { setTelegramConnected } from '../redux/reducers/login-flow.reducer';
import { QueryKeys } from '../constants';
import { clearTgDataCache } from '../redux/reducers/telegram-state.reducer';
import { setOnboardingVisibility } from '../redux/reducers/onboarding.reducer';
import { useGetMatchedWorkspaceId } from '../hooks/useGetWorkspaceId';
import { invoke, SignalRMethods, useTelegram } from '../services';
import { RootStore } from '../redux/createStore';
import { useNavigate } from '@router';
import { resetRequestPromise } from '../hooks/useChats';
import { persistor } from '../redux/createStore';

const DASHBOARD_FILTERS_KEY = 'dashboard_filters';

export const useLogout = () => {
    const tg = useTelegram();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceId = useGetMatchedWorkspaceId();
    const { signalRConnected } = useSelector(
        (state: RootStore) => state.signalR,
    );
    const queryClient = useQueryClient();

    return useMutation<
        ApiResponseEmpty,
        unknown,
        { accessToken: string; refreshToken: string }
    >({
        mutationKey: [QueryKeys.LOGOUT],
        mutationFn: (payload) => API.post('/api/auth/logout', payload),
        onSettled: async () => {
            try {
                if (workspaceId && signalRConnected) {
                    void invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
                }

                dispatch(logout());
                dispatch(setTelegramConnected({ isConnected: false }));
                dispatch(clearTgDataCache());
                dispatch(setOnboardingVisibility(false));

                await tg?.actions.proxy.signOut({
                    forceInitApi: true,
                });

                await queryClient.cancelQueries();
                queryClient.clear();
                resetRequestPromise();
                await queryClient.resetQueries();

                await persistor.purge();

                if ('serviceWorker' in navigator) {
                    try {
                        const registrations =
                            await navigator.serviceWorker.getRegistrations();
                        const appWorker = registrations.find(
                            (reg) =>
                                reg.scope.includes('localhost:1234') ||
                                reg.scope.includes('localhost:5173') ||
                                reg.scope.includes(
                                    'test.slise-crm-app.pages.dev',
                                ) ||
                                reg.scope.includes('app.trydise.com'),
                        );
                        if (appWorker) {
                            await appWorker.unregister();
                        }
                    } catch (swError) {
                        console.error(
                            'Error clearing service worker:',
                            swError,
                        );
                    }
                }

                if ('caches' in window) {
                    try {
                        const cacheKeys = await caches.keys();
                        const telegramCaches = cacheKeys.filter(
                            (key) =>
                                key.startsWith('tt-') &&
                                key.includes('trydise.com'),
                        );
                        await Promise.all(
                            telegramCaches.map((key) => caches.delete(key)),
                        );
                    } catch (cacheError) {
                        console.error(
                            'Error clearing Telegram caches:',
                            cacheError,
                        );
                    }
                }

                sessionStorage.removeItem(DASHBOARD_FILTERS_KEY);
                sessionStorage.removeItem('previousNav');

                const authCookies = [
                    'accessToken',
                    'refreshToken',
                    'sessionId',
                ];
                authCookies.forEach((cookieName) => {
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
                });

                navigate('/login');

                setTimeout(() => {
                    window.location.reload();
                }, 100);
            } catch (error) {
                console.error('Error during logout cleanup:', error);
                navigate('/login');
            }
        },
    });
};
