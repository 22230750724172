import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Text,
    Flex,
    HStack,
    Center,
    Spinner,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { TeamChatSelector } from './components';
import { InputText } from '../../components';
import { UploadWorkspaceAvatar } from '../../modals/Onboarding/components';
import {
    useGetWorkspaceInfo,
    useUpdateWorkspaceLogo,
    useUpdateWorkspaceInfo,
} from './queries';
import {
    updateWorkspaceResolver,
    UpdateWorkspaceForm,
} from '../../modals/Onboarding/validation';
import { DangerZone } from './components/DangerZone';
import { SectionTitle } from './components/SectionTitle';
import { SectionDescription } from './components/SectionDescription';
import { useFeatureFlags } from '../../featureFlags/useFeatureFlags';
import { trpc } from '@core/api/trpc';
import { useGetWorkspaceId } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const SettingsPage = () => {
    // State
    const [imageFromFile, setImageFromFile] = useState('');

    // Hooks
    const workspaceId = useGetWorkspaceId();
    const navigate = useNavigate();
    const flags = useFeatureFlags();

    // Form hooks
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm<UpdateWorkspaceForm>({ resolver: updateWorkspaceResolver });

    // Queries and mutations
    const { data: workspaceData, isFetching } = useGetWorkspaceInfo();
    const updateWorkspaceInfoMutation = useUpdateWorkspaceInfo();
    const updateWorkspaceLogoMutation = useUpdateWorkspaceLogo(1);
    const deleteWorkspaceLogoMutation = useUpdateWorkspaceLogo(2);
    const ownershipQuery = trpc.workspace.checkWorkspaceOwner.useQuery(
        { workspaceId },
        { retry: false },
    );

    const nameWatch = watch('name');

    // Effects
    useEffect(() => {
        if (ownershipQuery.isError) {
            navigate(`/${workspaceId}`);
        }
    }, [ownershipQuery.isError, workspaceId, navigate]);

    useEffect(() => {
        if (workspaceData?.value?.workspaceName) {
            reset({ name: workspaceData?.value?.workspaceName });
        }
    }, [workspaceData?.value, reset]);

    useEffect(() => {
        if (workspaceData?.value?.logoUrl && !isFetching) {
            setImageFromFile(workspaceData?.value?.logoUrl);
        }
    }, [workspaceData?.value, isFetching]);

    // Handlers
    const showImage = (file: File) => {
        const imageDataUrl = URL.createObjectURL(file);
        setImageFromFile(imageDataUrl);
        const fd = new FormData();
        fd.append('NewImage', file);
        updateWorkspaceLogoMutation.mutate(fd);
    };

    const removeImage = () => {
        deleteWorkspaceLogoMutation.mutate(null, {
            onSuccess(data) {
                if (data.success) {
                    setImageFromFile('');
                }
            },
        });
    };

    const onSubmitName = (data: UpdateWorkspaceForm) => {
        updateWorkspaceInfoMutation.mutate(data.name, {
            onSuccess(data, variables) {
                if (data.success) {
                    reset({ name: variables });
                }
            },
        });
    };

    // Loading state
    if (ownershipQuery.isLoading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" color="blue.500" />
            </Center>
        );
    }

    // Render
    return (
        <Box as="section" id="settings-layout" h="100%" w="100%">
            <Box
                bg="white"
                h="100%"
                borderRadius="8px"
                border="1px solid"
                borderColor="gray.20"
                p="32px"
            >
                <Flex flexDirection="column" gap="32px" maxW="container.sm">
                    <Text color="tertiary" fontSize="16px" fontWeight="600">
                        Workspace
                    </Text>
                    <UploadWorkspaceAvatar
                        imageFromFile={imageFromFile}
                        removeImage={removeImage}
                        showImage={showImage}
                        title="Company logo"
                        removeLoading={deleteWorkspaceLogoMutation.isPending}
                        uploadLoading={updateWorkspaceLogoMutation.isPending}
                    />
                    <Box>
                        <SectionTitle>Company name</SectionTitle>
                        <SectionDescription mt="12px" mb="16px">
                            The name of your workspace. Will be used to name new
                            groups
                        </SectionDescription>
                        <form onSubmit={handleSubmit(onSubmitName)}>
                            <HStack align="flex-start" mt="16px">
                                <InputText
                                    {...register('name')}
                                    errorMsg={errors?.name?.message}
                                />
                                {nameWatch !==
                                workspaceData?.value?.workspaceName ? (
                                    <Button
                                        type="submit"
                                        isLoading={
                                            updateWorkspaceInfoMutation.isPending
                                        }
                                    >
                                        Save
                                    </Button>
                                ) : null}
                            </HStack>
                        </form>
                    </Box>
                    <TeamChatSelector />
                    <DangerZone />
                </Flex>
            </Box>
        </Box>
    );
};

export default SettingsPage;
