import React, { useCallback, useMemo } from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    chakra,
    useDisclosure,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Template, TemplatePopup } from './components';
import { useGetTemplates } from './queries';
import { useTelegram } from '../../../../services';
import { Loader, EmptyItemsText, Icon } from '../../../../components';
import { Alerter, parseHtmlAsFormattedText } from '../../../../utils';
import { useGetBoardCards } from '../../../Dashboard/queries';
import { CardDefaultStatusEnum } from '../../../../types';
import { colors } from '../../../../theme/colors';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../redux/createStore';

interface TemplatesProps {
    cardId: number;
    isUserHasAccess?: boolean;
}

export const Templates: React.FC<TemplatesProps> = ({
    cardId,
    isUserHasAccess,
}) => {
    const { data, isLoading = false } = useGetTemplates();
    const { data: cardsData } = useGetBoardCards();
    const currentUserId = useSelector((state: RootStore) => state.auth.user.id);
    const {
        onToggle: onToggleTemplatePopup,
        isOpen: isTemplatePopupOpen,
        onClose: onCloseTemplatePopup,
    } = useDisclosure();
    const { state } = useLocation();
    const tg = useTelegram();
    const userTemplates = useMemo(
        () =>
            data?.data
                ?.filter((elem) => elem.userId === currentUserId)
                .sort(
                    (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime(),
                ),
        [data, currentUserId],
    );
    const workspaceTemplates = useMemo(
        () =>
            data?.data
                ?.filter((elem) => elem.userId !== currentUserId)
                .sort(
                    (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime(),
                ),
        [data, currentUserId],
    );

    const isCardArchived = useMemo(
        () =>
            cardsData?.find((elem) => elem.cardId === cardId)?.status.typeId ===
            CardDefaultStatusEnum.ARCHIVE,
        [cardsData, cardId],
    );

    const onContentClick = useCallback(
        (content: string) => {
            (async () => {
                if (state?.chatTelegramId) {
                    const { text, entities } =
                        parseHtmlAsFormattedText(content);

                    await tg?.actions.proxy.clearDraft({
                        chatId: state.chatTelegramId.toString(),
                        isLocalOnly: true,
                        shouldKeepReply: true,
                    });

                    await tg?.actions.proxy.openChatWithDraft({
                        chatId: state.chatTelegramId.toString(),
                        text: {
                            text,
                            entities,
                        },
                        // threadId: -1,
                    });
                }
            })();
        },
        [tg, state],
    );

    if (isLoading) {
        return <Loader centerHeight="calc(100vh - 105px)" />;
    }

    return (
        <Box
            p="24px"
            overflowY="scroll"
            height="calc(100vh - 105px)"
            maxWidth="41.4vw"
        >
            <Flex alignItems="center" justify="space-between" mb="16px">
                <Heading color="primary" fontSize="16px" fontWeight="600">
                    Your templates
                    <chakra.span color="gray.35" ml={2}>
                        {userTemplates?.length || 0}
                    </chakra.span>
                </Heading>
                <TemplatePopup
                    isOpen={isTemplatePopupOpen}
                    onToggle={onToggleTemplatePopup}
                    onClose={onCloseTemplatePopup}
                >
                    <Button
                        variant="outline"
                        height="24px"
                        shadow="none"
                        px="8px"
                        py="3px"
                        borderRadius="4px"
                        color="secondary"
                        iconSpacing={1}
                        leftIcon={
                            <Icon
                                name="plus"
                                width="14"
                                height="14"
                                color={colors.secondary}
                            />
                        }
                        isActive={isTemplatePopupOpen}
                    >
                        Add template
                    </Button>
                </TemplatePopup>
            </Flex>
            {userTemplates?.length ? (
                <Box
                    borderColor="gray.20"
                    borderRadius="8px"
                    background="gray.10"
                    borderWidth="1px"
                    cursor={
                        isCardArchived || !isUserHasAccess
                            ? 'not-allowed'
                            : 'pointer'
                    }
                >
                    {userTemplates?.map((elem) => (
                        <Template
                            key={elem.id}
                            {...elem}
                            onContentClick={() => {
                                if (isCardArchived || !isUserHasAccess) {
                                    Alerter.error(
                                        'You do not have access to this chat',
                                    );
                                    return;
                                }

                                onContentClick(elem.content || '');
                            }}
                        />
                    ))}
                </Box>
            ) : (
                <EmptyItemsText text="There are no user templates at the moment" />
            )}
            <Flex
                alignItems="center"
                justify="space-between"
                mb="16px"
                mt="16px"
            >
                <Heading color="primary" fontSize="16px" fontWeight="600">
                    Team templates
                    <chakra.span color="gray.35" ml={2}>
                        {workspaceTemplates?.length || 0}
                    </chakra.span>
                </Heading>
            </Flex>
            {workspaceTemplates?.length ? (
                <Box
                    borderColor="gray.20"
                    borderRadius="8px"
                    background="gray.10"
                    borderWidth="1px"
                    cursor={
                        isCardArchived || !isUserHasAccess
                            ? 'not-allowed'
                            : 'pointer'
                    }
                >
                    {workspaceTemplates?.map((elem) => (
                        <Template
                            key={elem.id}
                            {...elem}
                            onContentClick={() => {
                                if (isCardArchived || !isUserHasAccess) {
                                    Alerter.error(
                                        'You do not have access to this chat',
                                    );
                                    return;
                                }

                                onContentClick(elem.content || '');
                            }}
                        />
                    ))}
                </Box>
            ) : (
                <EmptyItemsText text="There are no workspace templates at the moment" />
            )}
        </Box>
    );
};
