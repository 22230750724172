import {
    Box,
    Button,
    chakra,
    Checkbox,
    Flex,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useEffect, useState, useMemo } from 'react';
import { WarningBannerWrapper } from '../../../modals/Onboarding/components';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { useTelegramLock } from '../../../services/telegram/lockContext';
// reimport with reexported local type
import {
    ApiChat,
    ApiChatFolder,
} from '../../../../../telegram-tt/src/api/types';
import { useGetWorkspaceId, useGetCreatedByIdCards } from '../../../hooks';
import css from './index.module.css';
import { useGetCurrentUserQuery } from '@core/api';
import { useModals } from '@router';
import { useGetCurrentUserWorkspaceFoldersQuery } from '@core/api/queries/current-user-workspace-folders';
import { CardDefaultStatusEnum } from '../../../types';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateWorkspaceCardStatus } from '@queries/useUpdateWorkspaceCardStatus';
import { useGetWorkspaceCardsStatuses } from '@queries/useGetWorkspaceCardsStatuses';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { allowedForSyncChatTypes, QueryKeys } from '../../../constants';
import { current } from '@reduxjs/toolkit';
import { trpc } from '@core/api/trpc';
import { FolderChatSyncChatsMutationPayload } from '@core/api/types/sync-chats';

function Sync() {
    const lock = useTelegramLock();
    const modals = useModals();
    const { isSynced } = useSelector((state: RootStore) => state.syncState);
    const workspaceId = useGetWorkspaceId();
    const [isChatFoldersLoading, setIsChatFoldersLoading] =
        useState<boolean>(true);
    const { data: userFolders } = useGetCurrentUserWorkspaceFoldersQuery(
        workspaceId.toString(),
    );
    const { data: statusesData } = useGetWorkspaceCardsStatuses();
    const { getCreatedByIdCards } = useGetCreatedByIdCards();
    const { data: currentCards } = trpc.workspace.getWorkspaceCards.useQuery({
        workspaceId: Number(workspaceId),
    });
    const [chatFolders, setChatFolders] = useState<{
        byId: Record<number, ApiChatFolder>;
        orderedIds: number[];
    }>({
        byId: {},
        orderedIds: [],
    });

    const [selectedChatFoldersIds, setSelectedChatFoldersIds] = useState<
        number[]
    >([]);
    const [initialChatFoldersIds, setInitialChatFoldersIds] = useState<
        number[]
    >([]);
    const [isArchiveChecked, setIsArchiveChecked] = useState<boolean>(false);

    const getCurrentUserQuery = useGetCurrentUserQuery();
    const syncChatsMutation = trpc.workspace.syncCards.useMutation();
    const queryClient = useQueryClient();
    const { mutateAsync: updateWorkspaceCardStatus } =
        useUpdateWorkspaceCardStatus();

    const hasUnsyncedFolders = useMemo(() => {
        return initialChatFoldersIds.some(
            (id) => !selectedChatFoldersIds.includes(id),
        );
    }, [initialChatFoldersIds, selectedChatFoldersIds]);

    useGetBoardCards();

    useEffect(() => {
        if (!isSynced) return;

        lock.scheduleBackgroundEvent(async (tg) => {
            try {
                setIsChatFoldersLoading(true);

                const chatFolders = await tg.methods.proxy.fetchChatFolders();

                if (!chatFolders) return;

                setChatFolders(chatFolders);

                setIsChatFoldersLoading(false);
            } catch (error) {
                console.log(error);
            }
        });
    }, [isSynced]);

    useEffect(() => {
        if (userFolders?.folders && chatFolders.orderedIds.length > 0) {
            const userFolderIds = userFolders.folders.map(
                (folder) => folder.telegramFolderId,
            );
            setSelectedChatFoldersIds(userFolderIds);
            setInitialChatFoldersIds(userFolderIds);
        }
    }, [userFolders?.folders, chatFolders.orderedIds]);

    if (!isSynced || isChatFoldersLoading)
        return (
            <Flex
                h={'100%'}
                w={'100%'}
                minH={'25rem'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Spinner size={'xl'} />
            </Flex>
        );

    const handleContinue = async () => {
        try {
            const archiveStatus = statusesData?.value?.statuses?.find(
                (status) => status.typeId === CardDefaultStatusEnum.ARCHIVE,
            );

            await lock.scheduleBackgroundEvent(async (tg) => {
                if (!workspaceId) return;
                //
                const createdByIdCards = await getCreatedByIdCards(workspaceId);
                const currentUser = getCurrentUserQuery.data;
                const userCards = currentCards?.cards.filter(
                    (card) =>
                        createdByIdCards.find(
                            (createdCard) => createdCard.cardId === card.cardId,
                        )?.createdById === currentUser?.id,
                );

                const chatsIds = selectedChatFoldersIds.flatMap((folderId) => {
                    const folder = chatFolders.byId[folderId];
                    return [
                        ...folder.includedChatIds,
                        ...(folder.pinnedChatIds || []),
                    ];
                });
                const resolvedChatsInfos = await Promise.allSettled(
                    chatsIds.map((chatId) =>
                        tg.custom.proxy.getChatById(Number(chatId)),
                    ),
                );
                //
                console.log('currentCards', userCards);
                const cardsToArchive =
                    userCards?.filter(
                        (card) =>
                            !chatsIds.includes(
                                card.chatTelegramId.toString(),
                            ) &&
                            card.status.id !== CardDefaultStatusEnum.ARCHIVE,
                    ) || [];
                console.log('cardsToArchive', cardsToArchive);
                if (
                    archiveStatus &&
                    cardsToArchive.length > 0 &&
                    isArchiveChecked
                ) {
                    await Promise.all(
                        cardsToArchive.map((card) =>
                            updateWorkspaceCardStatus({
                                workspaceId: Number(workspaceId),
                                cardId: Number(card.cardId),
                                statusId: archiveStatus.id,
                            }),
                        ),
                    );
                }

                const chatsByIds = resolvedChatsInfos.reduce<
                    Record<string, ApiChat>
                >((acc, chat) => {
                    /**
                     * If no "value" presented, that means that
                     * "tg.custom.proxy.getChatById" returned "undefined".
                     * As usual that means that user is not presented in this chat.
                     * But ID of this chat can still be presented in user folder!
                     * E.g. "includedChatIds" array.
                     */
                    if (
                        chat.status === 'fulfilled' &&
                        chat.value?.chatShortInfo &&
                        allowedForSyncChatTypes.includes(
                            chat.value.chatShortInfo.type,
                        )
                    ) {
                        acc[chat.value.chatShortInfo.id] =
                            chat.value.chatShortInfo;
                    }
                    return acc;
                }, {});

                // NOTE: Possible feature is to omit "name: chatsByIds[chatId].title" and resolve it on the dashboard by ID.
                const foldersPayload = selectedChatFoldersIds.map(
                    (folderId) => {
                        const folder = chatFolders.byId[folderId];
                        const allChatIds = [
                            ...folder.includedChatIds,
                            ...(folder.pinnedChatIds || []),
                        ];

                        return {
                            telegramFolderId: folderId,
                            chats: allChatIds.reduce<
                                FolderChatSyncChatsMutationPayload[]
                            >((acc, chatId) => {
                                if (!chatsByIds[chatId]) return acc;
                                return acc.concat([
                                    {
                                        telegramChatId: Number(chatId),
                                        name:
                                            chatsByIds[chatId].title ||
                                            'Unknown chat name',
                                    },
                                ]);
                            }, []),
                        };
                    },
                );

                const payload = {
                    workspaceId: workspaceId,
                    folders: foldersPayload,
                };

                await syncChatsMutation.mutateAsync(payload);

                /**
                 * NOTE: Based on legacy BS backend, no manual navigation for now.
                 * But after "syncChatsMutation" call, user data on backend setted like:
                 *   isOnboarder: true;
                 *   workspace: [createdWorkspace];
                 * And after that, revalidation of getCurrentUserQuery will trigger redirection logic in "_redirects.tsx"...
                 */

                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_BOARD_CARDS],
                });
                queryClient.invalidateQueries({
                    queryKey: [
                        QueryKeys.TG_CHATS,
                        workspaceId.toString(),
                        'individual',
                    ],
                });
                await getCurrentUserQuery.refetch();
            });
        } catch (error) {
            console.log(error);
        }

        modals.close();
    };

    const handleSkip = async () => {
        modals.close();
    };

    const handleCheckChatFolder = (chatFolderId: number) => {
        setSelectedChatFoldersIds((previousState) => {
            if (previousState.includes(chatFolderId))
                return previousState.filter((id) => id !== chatFolderId);
            return [...previousState, chatFolderId];
        });
    };

    const filteredChatFolders = {
        byId: Object.entries(chatFolders.byId).reduce<
            Record<number, ApiChatFolder>
        >((acc, [id, folder]) => {
            if (folder.includedChatIds && folder.includedChatIds.length > 0) {
                acc[Number(id)] = folder;
            }
            return acc;
        }, {}),
        orderedIds: chatFolders.orderedIds.filter(
            (id) => chatFolders.byId[id]?.includedChatIds?.length > 0,
        ),
    };

    const nonEmptyCustomOnlyChatFolders = filteredChatFolders.orderedIds.reduce<
        ApiChatFolder[]
    >((acc, id) => {
        if (filteredChatFolders.byId[id])
            return acc.concat(filteredChatFolders.byId[id]);
        return acc;
    }, []);

    return (
        <Flex flexDirection="column" align="center" gap="30px">
            <Box>
                <Text
                    fontWeight="600"
                    fontSize="20px"
                    lineHeight="24px"
                    textAlign="center"
                >
                    Chats sync
                </Text>
                <Text textAlign="center" mt="16px" color="secondary">
                    Sync your Telegram chats with DISE by choosing folders that
                    you want to track.
                </Text>
            </Box>

            <Flex
                flexDirection="column"
                align="start"
                borderRadius="8px"
                border="1px solid"
                borderColor="gray.20"
                maxH="260px"
                overflowY="auto"
                bg="gray.10"
                w={'100%'}
            >
                {nonEmptyCustomOnlyChatFolders.length > 0 &&
                    nonEmptyCustomOnlyChatFolders.map((chatFolder) => {
                        return (
                            <Checkbox
                                key={chatFolder.id}
                                isChecked={selectedChatFoldersIds.includes(
                                    chatFolder.id,
                                )}
                                onChange={() =>
                                    handleCheckChatFolder(chatFolder.id)
                                }
                                className={css.chatFolderCheckbox}
                            >
                                {chatFolder.title}
                            </Checkbox>
                        );
                    })}
            </Flex>

            <VStack w="full" align="flex-start" spacing={2}>
                <WarningBannerWrapper iconName="info-circle">
                    <Text
                        fontSize="11px"
                        color="secondary"
                        fontWeight="400"
                        lineHeight="16px"
                    >
                        <chakra.span fontWeight="500">
                            We don&apos;t have access to your messages.
                        </chakra.span>{' '}
                        DISE is a Telegram client which displays messages served
                        from Telegram servers and use local cache to enable CRM
                        functionality (like "unanswered"). Only synced chats
                        appear in DISE.
                    </Text>
                </WarningBannerWrapper>
            </VStack>
            <Checkbox
                fontSize="8px"
                color="#333333"
                fontWeight="400"
                lineHeight="16px"
                isDisabled={
                    selectedChatFoldersIds.length === 0 || !hasUnsyncedFolders
                }
                className={css.archiveCheckbox}
                isChecked={isArchiveChecked}
                onChange={(e) => setIsArchiveChecked(e.target.checked)}
            >
                <Text
                    fontSize="13px"
                    color="secondary"
                    fontWeight="400"
                    lineHeight="16px"
                >
                    Archive all chats from the unsynced folder
                </Text>
            </Checkbox>
            <Button
                w="full"
                maxW="200px"
                alignSelf="center"
                boxShadow="0px 2px 4px 0px #00000000, 0px 0px 4px 0px #00000014"
                isDisabled={selectedChatFoldersIds.length === 0}
                isLoading={syncChatsMutation.isPending}
                onClick={handleContinue}
                bg={selectedChatFoldersIds.length > 0 ? 'white' : undefined}
                color={
                    selectedChatFoldersIds.length > 0 ? '#3333330' : undefined
                }
                _hover={{
                    bg:
                        selectedChatFoldersIds.length > 0
                            ? '#ebebeb'
                            : undefined,
                }}
            >
                Sync with DISE
            </Button>

            <Button w={'65%'} variant={'ghost'} onClick={handleSkip}>
                Close
            </Button>
        </Flex>
    );
}

export default Sync;
