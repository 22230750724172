import { useQuery } from '@tanstack/react-query';
import { useGetBoardCards } from '../features/Dashboard/queries';
import { useParams } from 'react-router-dom';
import { TelegramWindowContextType, useTelegram } from '../services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    ApiChat,
    ApiChatFullInfo,
    ApiMessage,
    ApiUser,
    ApiUserFullInfo,
} from '../../../telegram-tt/src/api/types';
import { QueryKeys } from '../constants';
import { atom, useAtom } from 'jotai';
import { type NormalizedChatData } from '../../../telegram-tt/src/api/gramjs/methods/chats';
import { Maybe } from '../types';
import { RootStore } from '../redux/createStore';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { trpc } from '@core/api/trpc';
import { useDebounceCallback, useDebounceValue } from 'usehooks-ts';

function _getChatInfo(
    chatId: string,
    normalizedData: Maybe<NormalizedChatData>,
) {
    if (!normalizedData) return null;
    const { chats, messages, users, lastMessages } = normalizedData;

    if (!lastMessages || !messages || !users || !chats) return null;

    const lastMessageId = lastMessages[chatId];
    const lastMessage = lastMessageId ? messages[lastMessageId] : undefined;
    const lastMessageSender = lastMessage?.senderId
        ? users[lastMessage.senderId]
        : undefined;

    return chats[chatId]
        ? {
              ...chats[chatId],
              lastMessage: {
                  ...lastMessage,
                  sender: lastMessageSender,
              },
          }
        : null;
}

// Singleton promise to prevent multiple requests
let requestPromise:
    | ReturnType<
          TelegramWindowContextType['methods']['proxy']['fetchUserChats']
      >
    | undefined;

export const resetRequestPromise = () => {
    requestPromise = undefined;
};

export const getChats = async ({
    tg,
    workspaceChatIds,
    pageParam,
}: {
    tg?: TelegramWindowContextType;
    workspaceChatIds: string[];
    pageParam?: { id: number | undefined; date: number | undefined };
}) => {
    if (!tg) return null;
    if (requestPromise) return requestPromise;

    requestPromise = tg.methods.proxy
        .fetchUserChats(workspaceChatIds, 5, pageParam)
        .finally(() => {
            requestPromise = undefined;
        });

    return requestPromise;
};

type IndividualChat = {
    chat: ApiChat | undefined;
    id: number;
    chatFullInfo: ApiChatFullInfo | undefined;
    msg: ApiMessage | undefined;
    lastMessageUserInfo: ApiUser | undefined;
    userFullInfo: ApiUserFullInfo | undefined;
};

type WorkspaceChat = {
    chatId: string;
    chatTitle: string;
};

const getIndividualChats = async ({
    tg,
    workspaceChatIds,
}: {
    tg?: TelegramWindowContextType;
    workspaceChatIds: WorkspaceChat[];
}) => {
    if (!tg) {
        console.warn('getIndividualChats: No Telegram connection available');
        return null;
    }

    if (workspaceChatIds.length === 0) {
        console.warn('getIndividualChats: No chat IDs provided');
        return null;
    }

    try {
        const chatPromises = workspaceChatIds.map(({ chatId, chatTitle }) => {
            const proxy = tg?.custom?.proxy;
            if (!proxy?.getChatWithLastMessageById)
                return Promise.resolve(null);
            return Promise.race([
                (
                    proxy.getChatWithLastMessageById(
                        +chatId,
                    ) as Promise<IndividualChat | null>
                )
                    .then(async (chat) => {
                        if (!chat) {
                            const chatByTitle =
                                await proxy.findChatByTitle(chatTitle);
                            console.log(
                                '[Debug] Found chat by title:',
                                chatByTitle,
                            );
                            if (!chatByTitle) return null;
                            return chatByTitle;
                        }
                        return chat;
                    })
                    .catch((error: Error) => {
                        console.error(
                            `Failed fetch for chat ID: ${chatId} (${chatTitle}):`,
                            error,
                        );
                        return null;
                    }),
                new Promise((_, reject) =>
                    setTimeout(
                        () => reject(new Error(`Timeout for chat ${chatId}`)),
                        10000,
                    ),
                ),
            ]);
        });

        const chat = (await Promise.all(chatPromises)) as IndividualChat[];

        if (!chat || chat.every((c) => !c)) {
            const error = new Error('Failed to fetch individual chats');
            console.error('getIndividualChats error:', error);
            throw error;
        }

        return chat || [];
    } catch (error) {
        console.error('Error in getIndividualChats:', error);
        throw error;
    }
};

const lastMessageByChatIdAtom = atom<Record<string, number>>({});
const messagesAtom = atom<Record<string, ApiMessage>>({});
const usersAtom = atom<Record<string, ApiUser>>({});

export const useChats = () => {
    const tg = useTelegram();
    const { workspaceId } = useParams();
    const { data: cards, isLoading } = useGetBoardCards();

    const teamChatId = useSelector(
        (state: RootStore) => state.auth.user.workSpace?.teamChat?.id,
    );
    const workspaceChatIds = useMemo(() => {
        const chatIds =
            cards?.map((card) => ({
                chatId: card.chatTelegramId.toString(),
                chatTitle: card.cardName,
            })) || [];
        if (
            teamChatId &&
            !chatIds.some((id) => id.chatId === teamChatId.toString())
        ) {
            chatIds.push({
                chatId: teamChatId.toString(),
                chatTitle:
                    cards?.find((card) => card.chatTelegramId === teamChatId)
                        ?.cardName || '',
            });
        }
        return chatIds;
    }, [cards, teamChatId]);

    const individualChatsQuery = useQuery({
        queryKey: [QueryKeys.TG_CHATS, workspaceId, 'individual'],
        queryFn: async () => {
            return await getIndividualChats({ tg, workspaceChatIds });
        },
        retryDelay: 6000,
        retry(failureCount) {
            if (failureCount === 0) return true;
            return false;
        },
        enabled:
            !!tg && !isLoading && workspaceChatIds?.length > 0 && !!workspaceId,
    });

    const [, setLastMessageByChatId] = useAtom(lastMessageByChatIdAtom);
    const [, setMessages] = useAtom(messagesAtom);
    const [, setUsers] = useAtom(usersAtom);

    const combinedData = useMemo(() => {
        if (!individualChatsQuery.data) return null;

        return individualChatsQuery.data.reduce(
            (acc, item) => {
                if (!item || !item.chat) return acc;
                const { chat, msg, lastMessageUserInfo, id } = item;

                return {
                    chats: { ...acc.chats, [id]: chat },
                    users: {
                        ...acc.users,
                        ...(lastMessageUserInfo
                            ? { [lastMessageUserInfo.id]: lastMessageUserInfo }
                            : {}),
                    },
                    messages: {
                        ...acc.messages,
                        ...(msg ? { [msg.id]: msg } : {}),
                    },
                    lastMessages: {
                        ...acc.lastMessages,
                        ...(msg ? { [id]: msg.id } : {}),
                    },
                };
            },
            {
                chats: {},
                users: {},
                messages: {},
                lastMessages: {},
            } as {
                chats: Record<string, ApiChat>;
                users: Record<string, ApiUser>;
                messages: Record<string, ApiMessage>;
                lastMessages: Record<string, number>;
            },
        );
    }, [individualChatsQuery.data]);

    useEffect(() => {
        if (!combinedData) return;

        setLastMessageByChatId(combinedData.lastMessages || {});
        setMessages(combinedData.messages || {});
        setUsers(combinedData.users || {});
    }, [combinedData, setLastMessageByChatId, setMessages, setUsers]);

    const updateLastActivity = trpc.cards.updateLastActivity.useMutation();

    useEffect(() => {
        if (!individualChatsQuery.data || !workspaceId) return;
        const workspaceChats = individualChatsQuery.data
            .map((item) => {
                if (!item || !item.chat || !item.msg) return null;
                const card = cards?.find(
                    (card) => card.chatTelegramId === item.id,
                );
                if (!card) return null;
                return {
                    workspaceId: Number(workspaceId),
                    cardId: card?.cardId,
                    lastActivity: new Date(
                        (getChatInfo(card.chatTelegramId.toString())
                            ?.lastMessage.date ?? 0) * 1000,
                    ).toISOString(),
                };
            })
            .filter(
                (
                    item,
                ): item is {
                    workspaceId: number;
                    cardId: number;
                    lastActivity: string;
                } => item !== null,
            );

        // updateLastActivity.mutate(workspaceChats);
    }, [individualChatsQuery.data, workspaceId]);

    const getChatInfo = useCallback(
        (chatId: string) => _getChatInfo(chatId, combinedData),
        [combinedData],
    );

    const emptyData = {
        chats: {} as Record<string, ApiChat>,
        users: {} as Record<string, ApiUser>,
        messages: {} as Record<string, ApiMessage>,
        lastMessages: {} as Record<string, number>,
    };

    return {
        queryData: combinedData,
        data: combinedData || emptyData,
        hasNextPage: false, // Individual chats are loaded all at once
        fetchNextPage: () => Promise.resolve(), // No-op since we don't have pagination
        isLoading: individualChatsQuery.isLoading,
        getChatInfo,
    };
};

/**
 * Forced to use this hook and extra atoms to ensure that the last message is updated in the state
 * and rendered in the UI
 *
 * Tried optimistic update but it didn't work as expected - the last message was not updated in the UI
 */
export const useLastMessageByChatId = (chatId?: string) => {
    const [lastMessageByChatId, setLastMessageByChatId] = useAtom(
        lastMessageByChatIdAtom,
    );
    const [messages, setMessages] = useAtom(messagesAtom);
    const [users] = useAtom(usersAtom);
    const lastMessageId = useMemo(
        () => (chatId ? lastMessageByChatId[chatId] : undefined),
        [chatId, lastMessageByChatId],
    );

    const lastMessage = useMemo(
        () => (lastMessageId ? messages[lastMessageId] : undefined),
        [lastMessageId, messages],
    );

    const lastMessageSender = useMemo(
        () => (lastMessage?.senderId ? users[lastMessage.senderId] : undefined),
        [lastMessage?.senderId, users],
    );

    const setMessage = useCallback(
        (chatId: string, lastMessage: ApiMessage) => {
            const lastMessageId = lastMessage.id;
            setLastMessageByChatId({
                ...lastMessageByChatId,
                [chatId]: lastMessageId,
            });
            setMessages({
                ...messages,
                [lastMessageId]: lastMessage,
            });
        },
        [lastMessageByChatId, messages, setLastMessageByChatId, setMessages],
    );

    return {
        message: {
            ...lastMessage,
            sender: lastMessageSender,
        },
        setMessage,
    };
};

export type ChatItem = ReturnType<ReturnType<typeof useChats>['getChatInfo']>;
