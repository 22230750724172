import { useQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { BoardCardItemResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { trpc } from '@core/api/trpc';

export const useGetBoardCards = () => {
    const workspaceId = useGetMatchedWorkspaceId();
    const { data: ownersData } = trpc.cards.getAllOwnersCards.useQuery(
        { workspaceId },
        { enabled: !!workspaceId },
    );

    return useQuery({
        queryKey: [QueryKeys.GET_BOARD_CARDS, workspaceId],
        queryFn: () => API.get(`/api/workspaces/${workspaceId}/cards`, {}),
        refetchOnWindowFocus: true,
        enabled: !!workspaceId,
        refetchInterval: 30 * 1000, //refetch data every 30s
        select: (data) => {
            const cards = (data.value?.cards ?? []) as BoardCardItemResponse[];
            // Merge owner information
            return cards.map((card) => ({
                ...card,
                ownerId:
                    ownersData?.find(
                        (ownerCard) => ownerCard.cardId === card.cardId,
                    )?.cardOwnerId ?? card.createdById,
            }));
        },
    });
};
