/* eslint-disable quotes  */

import React, { useMemo } from 'react';
import { Button, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    Icon,
    CustomTag,
} from '../../../../components';
import {
    Alerter,
    getSystemDefaultStatusData,
    getTaskStatusByType,
} from '../../../../utils';
import {
    useGetWorkspaceCardsStatuses,
    useUpdateWorkspaceCardStatus,
} from '../../../../queries';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUpdateBoardCardData } from '../../../Dashboard/hooks';
import { useGetCardById } from '../../../../hooks';

import styles from './StatusSelectPopup.module.css';

interface StatusSelectPopupProps {
    workspaceId?: number;
    cardId?: number;
}

export const StatusSelectPopup: React.FC<StatusSelectPopupProps> = ({
    workspaceId,
    cardId,
}) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { data: statusesData } = useGetWorkspaceCardsStatuses();
    const { mutateAsync: updateCardStatus } = useUpdateWorkspaceCardStatus();
    const { updateBoardCardData } = useUpdateBoardCardData();
    const { state, pathname } = useLocation();
    const navigate = useNavigate();
    const { matchedCard } = useGetCardById(cardId || 0);

    const statusId = useMemo(
        () => matchedCard?.status?.id ?? 1,
        [matchedCard?.status?.id],
    );

    //this is for proper handle status color (text, bg)
    //TODO remove it, after backend include colors into statuses response (same for SidebarChatView component)
    const selectedTagData = useMemo(
        () =>
            getSystemDefaultStatusData(statusId, statusesData?.value?.statuses),
        [statusId, statusesData?.value?.statuses],
    );

    const onStatusChange = async (newStatusId: number) => {
        if (!workspaceId) {
            return Alerter.error("Workspace didn't found");
        }
        if (!cardId) {
            return Alerter.error("Chat didn't found");
        }

        const res = await updateCardStatus({
            workspaceId,
            cardId,
            statusId: newStatusId,
        });

        if (!res.success) {
            return;
        }

        navigate(pathname, {
            state: { ...state, statusId: newStatusId },
            replace: true,
        });
        updateBoardCardData(+cardId, newStatusId);
        onClose();
    };

    const renderTags = statusesData?.value?.statuses?.map((status) => {
        const tagData = getTaskStatusByType({
            typeId: status.typeId,
            position: status.position,
        });
        return (
            <Button
                key={status.id}
                variant="unstyled"
                display="flex"
                h="32px"
                maxW="100%"
                w="full"
                p="6px 4px"
                borderRadius="4px"
                justifyContent="flex-start"
                whiteSpace="normal"
                textAlign="left"
                onClick={() => onStatusChange(status.id)}
                _hover={{
                    bg: 'transparentLight.10',
                }}
            >
                <CustomTag
                    label={status.name}
                    bgColor={tagData.bgColor}
                    labelColor={tagData.textColor}
                    wrapperProps={{ maxW: '100%' }}
                />
            </Button>
        );
    });

    return (
        <Popover open={isOpen} onOpenChange={onToggle}>
            <PopoverTrigger asChild={false}>
                <HStack spacing={1} align="center">
                    {selectedTagData ? (
                        <CustomTag
                            label={selectedTagData.title}
                            bgColor={selectedTagData.bgColor}
                            labelColor={selectedTagData.textColor}
                            wrapperProps={{
                                maxW: '125px',
                                textAlign: 'left',
                            }}
                        />
                    ) : null}
                    <Icon name="chevron-down" height="16" width="16" />
                </HStack>
            </PopoverTrigger>
            <PopoverContent
                sideOffset={5}
                className={styles.StatusSelectPopoverContent}
            >
                <VStack align="flex-start" spacing="2px">
                    {renderTags}
                </VStack>
            </PopoverContent>
        </Popover>
    );
};
