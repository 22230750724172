import Tree from 'rc-tree';
import { DataNode } from 'rc-tree/lib/interface';
import { useMemo } from 'react';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { useGetWorkspaceCardsStatuses } from '@queries/useGetWorkspaceCardsStatuses';
import { switcherIcon } from '@features/batch-sending/select-cards/icons';
import 'rc-tree/assets/index.css';
import styles from './CardSelectionTree.module.css';
import { isEmptyCard } from '../../utils';

export const statusKeyPrefix = 'status:';
export const cardKeyPrefix = 'card:';

interface CardSelectionTreeProps {
    checkedKeys: string[];
    onCheck: (checkedKeys: string[]) => void;
    excludeCardId?: string;
    filterPredicate?: (card: any) => boolean;
    className?: string;
    checkStrictly?: boolean;
    selectionMode?: 'single' | 'multiple';
}

export const CardSelectionTree = ({
    checkedKeys,
    onCheck,
    excludeCardId,
    filterPredicate,
    className,
    checkStrictly = false,
    selectionMode = 'multiple',
}: CardSelectionTreeProps) => {
    const getBoardCards = useGetBoardCards();
    const getWorkspaceCardsStatuses = useGetWorkspaceCardsStatuses();

    const treeData = useMemo(() => {
        if (
            getBoardCards.isLoading ||
            getWorkspaceCardsStatuses.isLoading ||
            !getBoardCards.data ||
            !getWorkspaceCardsStatuses.data
        ) {
            return [];
        }

        const { data: cards } = getBoardCards;
        const { statuses } = getWorkspaceCardsStatuses.data.value;

        let filteredCards = cards.filter(
            (card) =>
                // Filter out cards without chatTelegramId and empty cards
                card.chatTelegramId != null &&
                card.chatTelegramId !== undefined &&
                !isEmptyCard(card.chatTelegramId),
        );

        if (excludeCardId) {
            filteredCards = filteredCards.filter(
                (card) => card.cardId !== parseInt(excludeCardId),
            );
        }

        if (filterPredicate) {
            filteredCards = filteredCards.filter(filterPredicate);
        }

        const cardsByStatusIdMap = Map.groupBy(
            filteredCards,
            ({ status }) => status.id,
        );

        return statuses.reduce<DataNode[]>((acc, { id, name }) => {
            const cardsOfStatus = cardsByStatusIdMap.get(id);

            if (!cardsOfStatus?.length) return acc;

            return acc.concat({
                key: `${statusKeyPrefix}${id}`,
                title: name,
                children: cardsOfStatus.map(({ cardId, cardName }) => ({
                    key: `${cardKeyPrefix}${cardId}`,
                    title: cardName,
                    isLeaf: true,
                })),
                checkable: checkStrictly,
            });
        }, []);
    }, [
        getBoardCards.data,
        getWorkspaceCardsStatuses.data,
        excludeCardId,
        filterPredicate,
        checkStrictly,
    ]);

    const handleCheck = (checkedKeys: any, info: any) => {
        if (selectionMode === 'single') {
            const newKey = info.node.key;
            const isChecked = info.checked;

            if (newKey.startsWith(cardKeyPrefix)) {
                if (isChecked) {
                    onCheck([newKey]);
                } else {
                    onCheck([]);
                }
            }
        } else {
            onCheck(checkedKeys.checked || checkedKeys);
        }
    };

    if (getBoardCards.isLoading || getWorkspaceCardsStatuses.isLoading) {
        return <>Loading</>;
    }

    if (getBoardCards.isError || getWorkspaceCardsStatuses.isError) {
        return <>Error</>;
    }

    return (
        <Tree
            checkable={true}
            selectable={false}
            defaultExpandAll={true}
            treeData={treeData}
            checkedKeys={checkedKeys}
            onCheck={handleCheck}
            switcherIcon={switcherIcon}
            showIcon={false}
            className={`${styles.tree} ${className || ''}`}
            checkStrictly={checkStrictly}
        />
    );
};
