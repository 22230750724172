import { BoardCardItemResponse } from '@features/Dashboard/types';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useChats } from '../../../../hooks/useChats';

type FilterHandler<T> = (
    card: BoardCardItemResponse,
    chats: ReturnType<typeof useChats>,
    filterValue: T,
) => boolean;

const filtersSchema = z
    .object({
        owner: z.string(),
        labelId: z.coerce.number(),
        lastActivity: z
            .string()
            .transform((x) => JSON.parse(x))
            .pipe(
                z.object({
                    operator: z.union([z.literal('lt'), z.literal('gt')]),
                    timeMs: z.number(),
                }),
            ),
    })
    .partial();

export type Filters = z.infer<typeof filtersSchema>;

export const useCardFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const applyFilters = (
        card: BoardCardItemResponse,
        chats: ReturnType<typeof useChats>,
    ) => {
        const filtersResult = Object.keys(filterHandlers).map((x) => {
            const filterName = x as keyof Filters;
            const param = searchParams.get(filterName);
            if (param === null) return true;

            const result = filtersSchema.shape[filterName].safeParse(param);

            if (!result.success) {
                console.warn(
                    `Failed to parse filter ${filterName} with value ${param}`,
                );
                return true;
            }
            const filterValue = result.data;

            const filterHandler = filterHandlers[filterName] as FilterHandler<
                typeof filterValue
            >;

            return filterHandler(card, chats, filterValue);
        });
        return filtersResult.every(Boolean);
    };

    const addFilter = <T extends keyof Filters>(
        filterName: T,
        filterValue: NonNullable<Filters[T]>,
    ) => {
        searchParams.set(
            filterName,
            typeof filterValue === 'string'
                ? filterValue
                : JSON.stringify(filterValue),
        );
        setSearchParams(searchParams);
    };
    const removeFilter = (filterName: keyof Filters) => {
        searchParams.delete(filterName);
        setSearchParams(searchParams);
    };

    const getFilterValue = <T extends keyof Filters>(
        filterName: T,
    ): Filters[T] => {
        const result = filtersSchema.shape[filterName].safeParse(
            searchParams.get(filterName),
        );
        if (result.success) {
            return result.data as Filters[T];
        }
        return undefined;
    };

    const hasActiveFilters = () =>
        Object.keys(filterHandlers).some((x) => {
            const filterName = x as keyof Filters;
            return searchParams.has(filterName);
        });

    return {
        applyFilters,
        addFilter,
        removeFilter,
        getFilterValue,
        hasActiveFilters,
    };
};

type FilterHandlers = Required<{
    [FilterName in keyof Filters]: FilterHandler<
        Exclude<Filters[FilterName], undefined>
    >;
}>;

const filterHandlers: FilterHandlers = {
    owner: (card, chats, owner) => {
        return card?.ownerId === Number(owner);
    },
    labelId: (card, chats, labelId) => {
        if (!labelId) return true;

        return (
            card.labels?.some(
                (label) => label.workspaceLabelId === Number(labelId),
            ) ?? false
        );
    },
    lastActivity: (card, chats, lastActivity) => {
        const chat = chats?.getChatInfo(card.chatTelegramId.toString());
        const time = Date.now() - lastActivity.timeMs;
        const lastMessageDateMs = (chat?.lastMessage.date ?? 0) * 1000;

        switch (lastActivity.operator) {
            case 'lt':
                return time < lastMessageDateMs;
            case 'gt':
                return time > lastMessageDateMs;
        }
    },
};
