import React, { useEffect } from 'react';
import {
    Box,
    Flex,
    HStack,
    Heading,
    Skeleton,
    Text,
    VStack,
} from '@chakra-ui/react';
import { LabelsSection, LastActivitySection } from './sections';
import { LabelItemType } from '../../../../types';
import { OwnerSection } from './sections/Owner.section';
import { trpc } from '@core/api/trpc';
import { ChatInfoSection } from './sections/ChatInfo.section';
import { CompanySection } from './sections/Company.section';
import { useAtom } from 'jotai';
import { cardTitlesAtom } from '../../../../atoms/cardTitlesAtom';
import { cardInfoAtom } from '../../../../atoms/cardInfoAtom';

interface InfoProps {
    cardTitle: string;
    cardId: number;
    workspaceId: number;
    isCardDataLoading: boolean;
    cardLabels: LabelItemType[];
    chat: any;
}

export const Info: React.FC<InfoProps> = ({
    cardTitle,
    workspaceId,
    cardId,
    isCardDataLoading,
    cardLabels,
    chat,
}) => {
    const [cardTitles, setCardTitles] = useAtom(cardTitlesAtom);
    const [cardInfo, setCardInfo] = useAtom(cardInfoAtom);

    const cardOwnerQuery = trpc.cards.getCardAndOwner.useQuery(
        {
            cardId: cardId,
            workspaceId: workspaceId,
        },
        {
            enabled: !!cardId && !!workspaceId,
        },
    );

    const teamMembersQuery = trpc.cards.getAllTeamMembers.useQuery({
        workspaceId: workspaceId,
    });

    const updateCardChatInfo = trpc.cards.updateCardChatInfo.useMutation({
        onSuccess: (_, { cardId, chatInfo }) => {
            setCardInfo((prev) => ({
                ...prev,
                [cardId]: chatInfo,
            }));
        },
    });

    const utils = trpc.useUtils();

    const updateOwnerMutation = trpc.cards.updateCardOwner.useMutation({
        onSuccess: () => {
            utils.cards.getCardAndOwner.invalidate({
                cardId,
                workspaceId,
            });
        },
    });

    const handleOwnerChange = (userId: number | null) => {
        updateOwnerMutation.mutate({
            cardId,
            workspaceId,
            ownerId: userId,
        });
    };

    const onInfoChange = async (info: string) => {
        await updateCardChatInfo.mutateAsync({
            cardId: cardId,
            chatInfo: info,
            workspaceId: workspaceId,
        });
    };

    const mappedTeamMembers = React.useMemo(() => {
        return (teamMembersQuery.data || []).map((member) => ({
            id: member.id,
            firstName: member.firstName,
            lastName: member.lastName,
            thumbnail: member.thumbnailId
                ? `storage/${member.thumbnailId}`
                : null,
            telegramUserId: member.telegramUserId,
        }));
    }, [teamMembersQuery.data]);

    const cardInfoQuery = trpc.cards.getCardChatInfo.useQuery(
        {
            cardId,
            workspaceId,
        },
        {
            enabled: !!cardId && !!workspaceId,
            staleTime: 0,
        },
    );

    useEffect(() => {
        if (cardInfoQuery.data) {
            setCardInfo((prev) => ({
                ...prev,
                [cardId]: cardInfoQuery.data.chatInfo,
            }));
        }
    }, [cardInfoQuery.data, cardId]);

    return (
        <Box p="24px">
            <Flex alignItems="center" justify="space-between" mb="16px">
                <Skeleton
                    isLoaded={!isCardDataLoading}
                    borderRadius="4px"
                    startColor="gray.40"
                    endColor="gray.20"
                    w={isCardDataLoading ? '200px' : 'auto'}
                >
                    <HStack spacing={2}>
                        <Heading
                            color="primary"
                            fontSize="16px"
                            fontWeight="600"
                            lineHeight="19.5px"
                            noOfLines={1}
                        >
                            {cardTitles[cardId] || cardTitle}
                        </Heading>
                        <Text fontWeight={600} fontSize="16px" color="gray.35">
                            Information
                        </Text>
                    </HStack>
                </Skeleton>
            </Flex>
            <Box
                p="12px"
                borderRadius="4px"
                borderWidth="1px"
                borderColor="gray.20"
            >
                <VStack spacing="12px" align="stretch">
                    <CompanySection cardId={cardId} workspaceId={workspaceId} />
                    <ChatInfoSection
                        cardId={cardId}
                        initialInfo={cardInfo[cardId]}
                        onInfoChange={onInfoChange}
                    />
                    <OwnerSection
                        cardOwnerQuery={cardOwnerQuery}
                        teamMembers={mappedTeamMembers}
                        workspaceId={workspaceId}
                        cardId={cardId}
                        onOwnerChange={handleOwnerChange}
                    />
                    <LabelsSection
                        workspaceId={workspaceId}
                        cardId={cardId}
                        cardLabels={cardLabels}
                    />
                    <LastActivitySection chat={chat} />
                </VStack>
            </Box>
        </Box>
    );
};
