import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { Icon, SoonTooltip } from '../../../../../../components';
import { ActionsMenu } from '../../../../../../components/ActionsMenu/ActionsMenu';
import { useDeleteMessage } from '../../queries';

interface BaseMessageActionsProps {
    workspaceId: number;
    cardId: number;
    messageId: number;
    isAction?: boolean;
}

export const BaseMessageActions: React.FC<BaseMessageActionsProps> = ({
    cardId,
    messageId,
    workspaceId,
    isAction,
}) => {
    const deleteMessageMutation = useDeleteMessage();
    return (
        <ActionsMenu
            includeDelete={true}
            sectionProps={{
                justifyContent: 'flex-end',
                className: 'action',
                right: isAction ? '0px' : '-56px',
                background: 'white',
                boxShadow: 'unset',
                transition: isAction
                    ? 'opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.4s ease-in-out'
                    : 'visibility 0.4s ease-in-out, opacity 0.4s ease-in-out, right 0.4s ease-in-out',
                transform: isAction
                    ? 'translateY(-50%) translateX(100%)'
                    : 'translateY(-50%)',
                visibility: 'hidden',
                opacity: 0,
            }}
            deleteButtonProps={{
                bg: 'white',
                isLoading: deleteMessageMutation.isPending,
                onClick: () =>
                    deleteMessageMutation.mutate({
                        workspaceId,
                        cardId,
                        messageId,
                    }),
            }}
        >
            {isAction ? null : (
                <SoonTooltip>
                    <IconButton
                        aria-label="edit template"
                        bg="white"
                        minW="auto"
                        variant="ghost"
                        boxSize="24px"
                        borderRadius="4px"
                    >
                        <Icon name="edit" height="16" width="16" />
                    </IconButton>
                </SoonTooltip>
            )}
        </ActionsMenu>
    );
};
