import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Flex, FormLabel, Button, Text, HStack } from '@chakra-ui/react';
import styles from '../Templates.module.css';
import {
	Editor,
	EditorProvider,
	Icon,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverClose,
	BtnItalic,
	BtnBold,
	BtnLink,
	SoonTooltip,
	BtnStrikeThrough,
	BtnUnderline,
} from '../../../../../components';
import { trpc } from '@core/api/trpc';
import { useGetWorkspaceId } from '../../../../../hooks';
import { RootStore } from '../../../../../redux/createStore';
import { useSelector } from 'react-redux';

interface TemplatePopupProps {
	template?: string;
	children: ReactElement;
	isOpen: boolean;
	onClose: VoidFunction;
	onToggle: VoidFunction;
	templateId?: number;
}

export const TemplatePopup = React.forwardRef<
	HTMLDivElement | null,
	TemplatePopupProps
>(({ template, templateId, children, isOpen, onClose, onToggle }, ref) => {
	const workspaceId = useGetWorkspaceId();
	const { id: currentUserId } = useSelector(
		(state: RootStore) => state.auth.user,
	);
	const utils = trpc.useUtils();
	const createTemplateMutation = trpc.templates.createTemplate.useMutation({
		onMutate: async ({ content, userId, workspaceId }) => {
			await utils.templates.getTemplates.cancel();
			const previousTemplates = utils.templates.getTemplates.getData({ workspaceId });

			// Optimistically add the new template
			utils.templates.getTemplates.setData({ workspaceId }, (old) => {
				if (!old) return old;
				const optimisticTemplate = {
					id: Math.random(), // temporary ID
					content,
					userId,
					workspaceId,
					createdAt: new Date().toISOString(),
					createdById: null,
					changedAt: null,
					changedById: null,
				};
				return {
					...old,
					data: [...old.data, optimisticTemplate],
				};
			});

			return { previousTemplates };
		},
		onError: (err, variables, context) => {
			if (context?.previousTemplates) {
				utils.templates.getTemplates.setData({ workspaceId }, context.previousTemplates);
			}
		},
		onSuccess(data) {
			if (data.success) {
				onClose();
				setText('');
			}
		},
		onSettled: () => {
			utils.templates.getTemplates.invalidate({ workspaceId });
		},
	});

	const updateTemplateMutation = trpc.templates.updateTemplate.useMutation({
		onMutate: async ({ content, templateId }) => {
			await utils.templates.getTemplates.cancel();
			const previousTemplates = utils.templates.getTemplates.getData({ workspaceId });

			// Optimistically update the template
			utils.templates.getTemplates.setData({ workspaceId }, (old) => {
				if (!old) return old;
				const newTemplates = old.data.map(t =>
					t.id === templateId ? { ...t, content, changedAt: new Date().toISOString() } : t
				);
				return {
					...old,
					data: newTemplates,
				};
			});

			return { previousTemplates };
		},
		onError: (err, variables, context) => {
			if (context?.previousTemplates) {
				utils.templates.getTemplates.setData({ workspaceId }, context.previousTemplates);
			}
		},
		onSuccess(data) {
			if (data.success) {
				onClose();
			}
		},
		onSettled: () => {
			utils.templates.getTemplates.invalidate({ workspaceId });
		},
	});

	const [text, setText] = useState(template || '');

	const onEditTemplate = (id: number) => {
		if (!id) {
			return;
		}
		updateTemplateMutation.mutate({ content: text, templateId: id });
	};

	const onCreateTemplate = () => {
		createTemplateMutation.mutate({
			userId: currentUserId,
			workspaceId,
			content: text,
		});
	};

	useEffect(() => {
		if (isOpen && template?.length) {
			setText(template || '');
		}
	}, [isOpen, template]);

	const handlePaste = (event: React.ClipboardEvent<HTMLElement>) => {
		const clipboardData = event.clipboardData;

		if (clipboardData && clipboardData.items) {
			for (let i = 0; i < clipboardData.items.length; i++) {
				const item = clipboardData.items[i];

				if (item.type.indexOf('image') !== -1) {
					event.preventDefault();
				}
			}
		}
	};

	return (
		<Popover open={isOpen} onOpenChange={onToggle} modal={true}>
			<PopoverTrigger asChild={true}>{children}</PopoverTrigger>
			<EditorProvider>
				<PopoverContent
					ref={ref}
					sideOffset={5}
					align="end"
					className={styles.PopoverContent}>
					<Flex
						flexDirection="row"
						padding="8px"
						borderBottomWidth="1px"
						borderBottomColor="gray.15"
						align="center"
						justify="space-between">
						<Text color="primary" fontSize="13px" fontWeight="600">
							{templateId ? 'Edit' : 'New'} template
						</Text>
						<PopoverClose aria-label="Close" onClick={onClose}>
							<Icon name="close-cross" height="16" width="16" />
						</PopoverClose>
					</Flex>
					<Flex
						padding="8px"
						gap="8px"
						flexDirection="column"
						borderBottomWidth="1px"
						borderBottomColor="gray.15"
						flex="1"
						minHeight="0"
						overflow="hidden">
						<Box display="flex" flexDirection="column" height="100%" minHeight="0">
							<FormLabel
								textTransform="uppercase"
								color="gray.35"
								fontSize="11px"
								fontWeight="600"
								mb="4px">
								Text
							</FormLabel>

							<Box flex="1" minHeight="0" overflow="auto">
								<Editor
									value={text}
									onChange={ev => setText(ev.target.value)}
									onPaste={handlePaste}></Editor>
							</Box>
							<Text
								fontSize="12px"
								color="gray.40"
								fontWeight="400">
								Copy and paste your frequently used messages
								from telegram or format them here.
							</Text>
						</Box>
					</Flex>
					<Flex justifyContent="space-between" padding="8px">
						<HStack spacing="8px">
							<BtnBold />
							<BtnItalic />
							<BtnUnderline />
							<BtnStrikeThrough />
							<BtnLink />
							<SoonTooltip>
								<Button
									isDisabled={true}
									variant="link"
									fontSize="11px"
									color="secondary"
									fontWeight={400}
									px="8px">
									Advanced formatting
								</Button>
							</SoonTooltip>
						</HStack>
						<Button
							variant="popup"
							isLoading={
								updateTemplateMutation.isPending ||
								createTemplateMutation.isPending
							}
							isDisabled={!text?.length}
							onClick={() => {
								if (!templateId) {
									return onCreateTemplate();
								}

								onEditTemplate(templateId);
							}}
							px="10px">
							{templateId ? 'Update' : 'Create'}
						</Button>
					</Flex>
				</PopoverContent>
			</EditorProvider>
		</Popover>
	);
});
