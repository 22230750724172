import { useCallback, useMemo } from 'react';
import {
    Box,
    Button,
    GridItem,
    HStack,
    IconButton,
    Text,
    VStack,
} from '@chakra-ui/react';
import { Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomTag, Icon } from '../../../components';
import { BoardCardItemResponse, BoardFilterValue } from '../types';
import {
    getColumnDragBackgroundColor,
    sortCardsByDateAndAccess,
    getTaskStatusByType,
    getUnreadCards,
    getUnansweredCards,
    getActiveTasksCards,
    getTeamActivityCards,
    getMentionedCards,
} from '../../../utils';
import { ApiResponse, CardStatusItem } from '../../../types';
import {
    setCardsFilterId,
    setCardsStatusId,
} from '../../../redux/reducers/page-navigation-state.reducer';
import { addRecentEntry } from '../../../redux/reducers/search-recent-stack.reducer';
import { useGetWorkspaceId } from '../../../hooks';
import { useAtomValue, useSetAtom } from 'jotai';
import { tasksAtom } from '@atoms/tasksAtom';
import DraggableTaskCard from './DraggableTaskCard';
import { useChats } from '../../../hooks/useChats';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useCardFilters } from './FilterSelect/useCardFilters';
import { createCardModalAtom } from '@atoms/modals.atoms';

interface KanbanBoardColumnProps {
    tabIndex: BoardFilterValue;
    columnStatus: CardStatusItem;
    enableColumnIcon?: boolean;
    isColumnIconActive?: boolean;
    onClickColumnIcon?: VoidFunction;
    handleCreateCard?: VoidFunction;
    chats: ReturnType<typeof useChats>;
}

interface ColumnHeaderProps {
    statusName: string;
    columnStyle: {
        textColor: string;
        bgColor: string;
        title?: string;
    };
    itemCount: number;
    enableColumnIcon?: boolean;
    isColumnIconActive?: boolean;
    onClickColumnIcon?: VoidFunction;
    handleCreateCard?: VoidFunction;
}

const ColumnHeader = ({
    statusName,
    columnStyle,
    itemCount,
    enableColumnIcon,
    isColumnIconActive,
    onClickColumnIcon,
    handleCreateCard,
}: ColumnHeaderProps) => (
    <HStack justify="space-between" spacing={2} w="full">
        <HStack spacing={2}>
            <CustomTag
                label={statusName}
                bgColor={columnStyle.bgColor}
                labelColor={columnStyle.textColor}
            />
            {itemCount > 0 && <Text>{itemCount}</Text>}
        </HStack>
        <IconButton
            aria-label="Create new card"
            minW="auto"
            boxSize="24px"
            variant="ghost"
            borderRadius="4px"
            onClick={handleCreateCard}
        >
            <Icon name="plus" height="16" width="16" color="gray.40" />
        </IconButton>
        {enableColumnIcon && (
            <IconButton
                aria-label={
                    isColumnIconActive
                        ? 'hide column cards'
                        : 'show column cards'
                }
                minW="auto"
                boxSize="24px"
                variant="outline"
                borderRadius="4px"
                bg="transparent"
                onClick={onClickColumnIcon}
            >
                <Icon
                    name={isColumnIconActive ? 'eye-off' : 'eye'}
                    height="16"
                    width="16"
                />
            </IconButton>
        )}
    </HStack>
);

interface KanbanBoardColumnViewProps extends KanbanBoardColumnProps {
    sortedTasks: BoardCardItemResponse[];
    dataByColumnType: {
        textColor: string;
        bgColor: string;
        title?: string;
    };
    onRedirect: (obj: {
        cardData: BoardCardItemResponse;
        statusId?: number;
        filterId?: number;
    }) => void;
    animateParent: any;
    backgroundColors: {
        default: string;
        draggingOver: string;
        draggingFrom: string;
    };
}

const KanbanBoardColumnView = ({
    columnStatus,
    tabIndex,
    enableColumnIcon,
    isColumnIconActive,
    onClickColumnIcon,
    handleCreateCard,
    sortedTasks,
    dataByColumnType,
    onRedirect,
    animateParent,
    backgroundColors,
    chats,
}: KanbanBoardColumnViewProps) => (
    <GridItem
        pl={2}
        borderRightWidth="1px"
        borderRightColor="gray.15"
        height="100%"
        display="flex"
        flexDirection="column"
    >
        <Box position="sticky" top={0} zIndex={1} bg="white" pb={2} pt={2}>
            <ColumnHeader
                statusName={columnStatus.name}
                columnStyle={dataByColumnType}
                itemCount={sortedTasks.length}
                enableColumnIcon={enableColumnIcon}
                isColumnIconActive={isColumnIconActive}
                onClickColumnIcon={onClickColumnIcon}
                handleCreateCard={handleCreateCard}
            />
        </Box>
        <Box flex={1} overflow="auto" pr={2}>
            <Droppable droppableId={`${columnStatus.id}`} type="tasks">
                {(provided, snapshot) => (
                    <Box
                        w="100%"
                        h="100%"
                        ref={provided.innerRef}
                        transition="background .15s ease-in-out"
                        bg={
                            snapshot.isDraggingOver
                                ? backgroundColors.draggingOver
                                : snapshot.draggingFromThisWith
                                  ? backgroundColors.draggingFrom
                                  : backgroundColors.default
                        }
                        {...provided.droppableProps}
                    >
                        <div ref={animateParent}>
                            {sortedTasks?.map((task, index) => {
                                if (enableColumnIcon && isColumnIconActive) {
                                    return null;
                                }

                                const chat = chats.getChatInfo(
                                    task.chatTelegramId.toString(),
                                );

                                return (
                                    <DraggableTaskCard
                                        key={task.cardId}
                                        task={task}
                                        index={index}
                                        columnStatusId={columnStatus.id}
                                        tabIndex={tabIndex}
                                        onRedirect={onRedirect}
                                        chat={chat}
                                        chats={chats}
                                    />
                                );
                            })}
                        </div>
                        {provided.placeholder}
                        <Button
                            w="100%"
                            variant="ghost"
                            size="sm"
                            height="40px"
                            leftIcon={
                                <Icon
                                    name="plus"
                                    color="gray.40"
                                    height="14px"
                                    width="14px"
                                />
                            }
                            justifyContent="flex-start"
                            color="gray.40"
                            fontWeight="400"
                            onClick={handleCreateCard}
                            _hover={{
                                bg: 'gray.15',
                                border: '1px solid gray.30',
                            }}
                        >
                            New
                        </Button>
                    </Box>
                )}
            </Droppable>
        </Box>
    </GridItem>
);

const useKanbanBoardColumn = ({
    tabIndex,
    columnStatus,
    chats,
}: KanbanBoardColumnProps) => {
    const [animateParent] = useAutoAnimate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaceId = useGetWorkspaceId();
    const queryClient = useQueryClient();
    const filters = useCardFilters();
    const tasks = useAtomValue(tasksAtom);
    const items = tasks[columnStatus.id];

    const teamTgIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    const setCreateCardModal = useSetAtom(createCardModalAtom);

    const dataByColumnType = useMemo(
        () =>
            getTaskStatusByType({
                position: columnStatus.position,
                typeId: columnStatus.typeId,
            }),
        [columnStatus.position, columnStatus.typeId],
    );

    const onRedirect = useCallback(
        (obj: {
            cardData: BoardCardItemResponse;
            statusId?: number;
            filterId?: number;
        }) => {
            navigate(`/${workspaceId}/chat/${obj.cardData.cardId}`, {
                state: {
                    statusId: obj.statusId,
                    filterId: obj.filterId,
                    chatTelegramId: obj.cardData.chatTelegramId,
                },
            });
            dispatch(setCardsStatusId(obj.statusId || 0));
            dispatch(setCardsFilterId(obj.filterId || 1));
            dispatch(addRecentEntry(obj.cardData));
        },
        [dispatch, navigate, workspaceId],
    );

    const getFilteredCards = useCallback(() => {
        let res: BoardCardItemResponse[] = [];
        const cards = items || [];
        switch (tabIndex) {
            case BoardFilterValue.All:
                res = cards;
                break;
            case BoardFilterValue.Unread:
                res = getUnreadCards({
                    cards,
                    chats,
                });
                break;
            case BoardFilterValue.Unanswered:
                res = getUnansweredCards({
                    cards,
                    chats,
                    teamTelegramIds: teamTgIds?.value?.telegramUserIds || [],
                });
                break;
            case BoardFilterValue.ActiveTasks:
                res = getActiveTasksCards({
                    cards,
                });
                break;
            case BoardFilterValue.TeamActivity:
                res = getTeamActivityCards({
                    cards,
                });
                break;
            case BoardFilterValue.Mentions:
                res = getMentionedCards({
                    cards,
                });
                break;
            default:
                res = [];
        }

        res = res.filter((card) => filters.applyFilters(card, chats));

        return res?.sort((cardA, cardB) =>
            sortCardsByDateAndAccess(
                cardA,
                cardB,
                chats.data.chats,
                chats.data.messages,
                chats.data.lastMessages,
            ),
        );
    }, [tabIndex, items, chats, teamTgIds, filters]);

    const backgroundColors = useMemo(
        () => ({
            default: 'transparent',
            draggingOver: getColumnDragBackgroundColor(true, false),
            draggingFrom: getColumnDragBackgroundColor(false, true),
        }),
        [],
    );

    const sortedTasks = getFilteredCards();

    const handleCreateCard = () => {
        setCreateCardModal({ isOpen: true, statusId: columnStatus.id });
    };

    return {
        sortedTasks,
        dataByColumnType,
        onRedirect,
        animateParent,
        backgroundColors,
        handleCreateCard,
    };
};

export const KanbanBoardColumn = (props: KanbanBoardColumnProps) => {
    const hookProps = useKanbanBoardColumn(props);
    return <KanbanBoardColumnView {...props} {...hookProps} />;
};
