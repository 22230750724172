import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, VStack, Box, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getAutomationNavLinkItems, getNavLinkItems } from '../constants';
import { SidebarNavItem } from './SidebarNavItem';
import { SidebarCompanyHeader } from './SidebarCompanyHeader';
import { LogoutButton } from './LogoutButton';
import { TaskCardSolo } from '../../TaskCard/TaskCardSolo';
import { TeamChatPlaceholder } from '../../TaskCard/TeamChatPlaceholder';
import { SidebarChatView } from './SidebarChatView';
import { SidebarSettingsView } from './SidebarSettingsView';
import { ApiResponse } from '../../../types';
import {
    useGetNotificationsCount,
    useGetUserTasksCount,
} from '../../../queries';
import { QueryKeys } from '../../../constants';
import {
    invoke,
    SignalRMethods,
    SignalRTopics,
    useSignalREffect,
} from '../../../services';
import { RootStore } from '../../../redux/createStore';
import {
    useGetMatchedCardId,
    useGetMatchedWorkspaceId,
    useGetWorkspaceId,
} from '../../../hooks';
import { UserInformBlock } from './UserInformBlock';
import { useChats } from '../../../hooks/useChats';
import { useModals } from '@router';
import { trpc } from '@core/api/trpc';
import { NotificationsCountUpdatePayload } from '../../../types';

export const SidebarContent = () => {
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    const modals = useModals();
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const workspaceId = useGetWorkspaceId();
    const cardId = useGetMatchedCardId();
    const chats = useChats();
    const { signalRConnected } = useSelector(
        (state: RootStore) => state.signalR,
    );

    const teamTgIds =
        queryClient.getQueryData<ApiResponse<{ telegramUserIds: number[] }>>([
            QueryKeys.GET_WORKSPACE_TELEGRAM_IDS,
            workspaceId,
        ])?.value?.telegramUserIds || [];

    const { isDashboard, isTasks } = useMemo(() => {
        const dashboardRouteRegex = /^\/\d+$/;
        const tasksRouteRegex = /^\/\d+\/tasks$/;
        const isDashboard = dashboardRouteRegex.test(location.pathname);
        const isTasks = tasksRouteRegex.test(location.pathname);

        return { isDashboard, isTasks };
    }, [location.pathname]);

    const { data: tasksCountData } = useGetUserTasksCount(
        isDashboard || isTasks,
    );

    const { data: notificationsCountData } = useGetNotificationsCount();

    // Check if user is workspace owner
    const ownershipQuery = trpc.workspace.checkWorkspaceOwner.useQuery(
        { workspaceId },
        {
            retry: false,
            enabled: !!workspaceId,
        },
    );

    // Compute view states based on location
    const showSidebarSettingsView = location.pathname.includes('settings');
    const showSidebarChatView = location.pathname.includes('chat');

    // Handle notifications count update
    useSignalREffect(
        SignalRTopics.NotificationCountUpdate,
        (data: NotificationsCountUpdatePayload) => {
            if (!workspaceId) {
                return;
            }
            const { isAdded } = data;

            queryClient.setQueryData<ApiResponse<{ count: number }>>(
                [QueryKeys.GET_NOTIFICATIONS_COUNT, workspaceId],
                (oldData) => {
                    if (oldData) {
                        return {
                            ...oldData,
                            value: {
                                count: isAdded
                                    ? oldData.value.count + 1
                                    : Math.max(0, oldData.value.count - 1),
                            },
                        };
                    }
                    return oldData;
                },
            );
        },
        [workspaceId],
    );

    // Filter navigation items
    const navigationItems = useMemo(() => {
        return getNavLinkItems(workspaceId).filter((elem) => {
            // Hide CRM Settings if user is not confirmed as owner
            if (elem.name === 'CRM Settings') {
                return ownershipQuery.data?.isOwner === true;
            }
            return true;
        });
    }, [workspaceId, ownershipQuery.data?.isOwner]);

    const onRedirect = (chatTelegramId?: number, cardId?: number) => {
        if (!chatTelegramId) {
            return;
        }

        navigate(`/${workspaceId}/chat/${cardId}`, {
            state: {
                chatTelegramId,
                isTeamChat: true,
                id: cardId,
            },
        });
    };

    useEffect(() => {
        if (workspaceId && (isTasks || isDashboard)) {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
            });
        }
    }, [isTasks, isDashboard, workspaceId, queryClient]);

    const getActivityCount = (id: number) => {
        if (id === 3) {
            return typeof notificationsCountData?.value === 'number'
                ? notificationsCountData.value
                : 0;
        }
        return 0;
    };

    useEffect(() => {
        const handleNotificationsCountUpdate = (
            data: NotificationsCountUpdatePayload,
        ) => {
            queryClient.setQueryData<ApiResponse<number>>(
                ['notifications-count'],
                (oldData) => ({
                    success: true,
                    value: data.count,
                    errors: oldData?.errors || [],
                }),
            );
        };

        window.electron?.on(
            'notifications-count-update',
            handleNotificationsCountUpdate,
        );

        return () => {
            window.electron?.off(
                'notifications-count-update',
                handleNotificationsCountUpdate,
            );
        };
    }, [queryClient]);

    useEffect(() => {
        if (signalRConnected && workspaceId) {
            invoke(SignalRMethods.WorkspaceJoinChat, workspaceId);
        }

        return () => {
            if (workspaceId && signalRConnected) {
                invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
            }
        };
    }, [signalRConnected, workspaceId]);

    const handleOpenBatchSending = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        modals.open('/batch-sending', {
            at: '/:workspaceId',
            params: { workspaceId: `${workspaceId}` },
        });
    };

    const handleOpenConfMode = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        modals.open('/conf-mode', {
            at: '/:workspaceId',
            params: { workspaceId: `${workspaceId}` },
        });
    };

    return (
        <>
            <Flex
                as="aside"
                direction="column"
                alignItems="space-between"
                h="100vh"
                p="0.75rem 4px 0.75rem 8px"
                w="220px"
                flexShrink={0}
                overflowY="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        width: 0,
                    },
                    '&::-webkit-scrollbar-track': {
                        width: 0,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: 0,
                    },
                }}
            >
                {workSpace ? (
                    <SidebarCompanyHeader
                        wrapperProps={{
                            display: {
                                base: 'none',
                                md: !showSidebarSettingsView ? 'flex' : 'none',
                            },
                        }}
                        showSidebarChatView={showSidebarChatView}
                    />
                ) : null}

                <Flex
                    direction="column"
                    alignItems="stretch"
                    position="relative"
                    id="kek"
                    display={{ base: 'flex', md: 'none' }}
                >
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        mx={{ base: 0, md: 4 }}
                    >
                        {workSpace ? (
                            <SidebarCompanyHeader
                                showSidebarChatView={showSidebarChatView}
                            />
                        ) : null}
                    </Flex>
                </Flex>

                {showSidebarChatView ? null : !showSidebarSettingsView ? (
                    <VStack spacing={'2px'} my={'2rem'} w={'100%'}>
                        {navigationItems.map((elem) => (
                            <SidebarNavItem
                                key={elem.id}
                                name={elem.name}
                                path={elem.path}
                                iconName={elem.iconName}
                                location={location.pathname}
                                isSoon={elem.isSoon}
                                isActive={elem.path === location.pathname}
                                activity={getActivityCount(elem.id)}
                            />
                        ))}

                        <LogoutButton />
                    </VStack>
                ) : null}

                {showSidebarChatView ? null : !showSidebarSettingsView ? (
                    <>
                        <Text
                            fontSize={'0.75rem'}
                            mb={'0.5rem'}
                            color={'#B3B3B3'}
                            fontWeight={600}
                            textTransform={'uppercase'}
                        >
                            Automation
                        </Text>

                        <VStack spacing={'2px'} w={'100%'} mb={'2rem'}>
                            <button
                                onClick={handleOpenBatchSending}
                                style={{ width: '100%' }}
                            >
                                <SidebarNavItem
                                    name={'Batch Send'}
                                    path={`/${workspaceId}`}
                                    iconName={'mail-share'}
                                    location={location.pathname}
                                    isSoon={false}
                                    isActive={false}
                                />
                            </button>
                            <button
                                onClick={handleOpenConfMode}
                                style={{ width: '100%' }}
                            >
                                <SidebarNavItem
                                    name={'Conference Mode™'}
                                    path={`/${workspaceId}`}
                                    iconName={'door'}
                                    location={location.pathname}
                                    isSoon={false}
                                    isActive={false}
                                />
                            </button>
                            {getAutomationNavLinkItems(workspaceId).map(
                                (elem) => (
                                    <SidebarNavItem
                                        key={elem.id}
                                        name={elem.name}
                                        path={elem.path}
                                        iconName={elem.iconName}
                                        location={location.pathname}
                                        isSoon={elem.isSoon}
                                        isActive={false}
                                    />
                                ),
                            )}
                        </VStack>
                    </>
                ) : null}

                <UserInformBlock isEnabled={isDashboard} />

                {showSidebarChatView ? (
                    <SidebarChatView teamTgIds={teamTgIds} />
                ) : null}

                {showSidebarSettingsView ? <SidebarSettingsView /> : null}

                {!showSidebarSettingsView ? (
                    <Box
                        mt="auto"
                        w="100%"
                        display={{ base: 'none', md: 'block' }}
                    >
                        {workSpace?.teamChat ? (
                            <TaskCardSolo
                                chats={chats}
                                chat={chats.getChatInfo(
                                    workSpace?.teamChat?.id.toString(),
                                )}
                                isDragging={false}
                                cardName={workSpace?.teamChat?.name || ''}
                                chatTelegramId={workSpace?.teamChat?.id || 0}
                                onRedirect={() =>
                                    onRedirect(
                                        workSpace?.teamChat?.id,
                                        workSpace?.teamChat?.cardId,
                                    )
                                }
                                isTeamChat={true}
                                cardId={workSpace.teamChat.cardId}
                                teamTgIds={teamTgIds}
                            />
                        ) : (
                            <TeamChatPlaceholder
                                teamChatData={workSpace?.teamChat}
                                isAdmin={workSpace?.isAdmin}
                            />
                        )}
                    </Box>
                ) : null}
            </Flex>
        </>
    );
};
