import React from 'react';
import {
    IconButton,
    IconButtonProps,
    Tag,
    TagLabel,
    TagLabelProps,
    TagProps,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';

interface CustomTagProps {
    label: string;
    bgColor: string;
    labelColor: string;
    wrapperProps?: TagProps;
    labelProps?: TagLabelProps;
    enableCloseButton?: boolean;
    closeButtonProps?: IconButtonProps;
    closeIconColor?: string;
    style?: React.CSSProperties;
}

export const CustomTag: React.FC<CustomTagProps> = ({
    label,
    bgColor,
    labelColor,
    wrapperProps,
    labelProps,
    enableCloseButton,
    closeButtonProps,
    closeIconColor = colors.primary,
    style,
}) => {
    return (
        <Tag
            w="auto"
            borderRadius="20px"
            h="20px"
            minH="20px"
            flexShrink={0}
            px={2}
            bg={bgColor}
            style={style}
            {...wrapperProps}
        >
            <TagLabel
                fontSize="13px"
                lineHeight="20px"
                fontWeight={500}
                color={labelColor}
                {...labelProps}
            >
                {label}
            </TagLabel>
            {enableCloseButton ? (
                <IconButton
                    aria-label="close-tag"
                    variant="solid"
                    width="14px"
                    minW="14px"
                    height="14px"
                    ml={1}
                    bg="transparentLight.10"
                    transition="0.3s background ease"
                    opacity={1}
                    _hover={{
                        bg: 'transparentLight.20',
                    }}
                    {...closeButtonProps}
                >
                    <Icon
                        name="rounded-close-cross"
                        width="8px"
                        height="9px"
                        color={closeIconColor}
                    />
                </IconButton>
            ) : null}
        </Tag>
    );
};
