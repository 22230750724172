import React from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useSteps,
} from '@chakra-ui/react';

import { useModals } from '@router';

import Onboarding from './toggle-telegram';
import CreateConferenceWorkspace from './create-conference-workspace';
// При желании можно подключить ваш CSS-модуль для общей стилизации обёртки
import css from './index.module.css'; 
import { ConferenceWorkspace } from './onboarding';
// Список шагов. Можно добавить больше при необходимости.

const steps = [
  { key: 'test', Content: ConferenceWorkspace },
  { key: 'onboarding', Content: Onboarding },
  // { key: 'create-conf-workspace', Content: CreateConferenceWorkspace },
];


function ConfMode() {
  const modals = useModals();

  // Подключение механизма шагов Chakra
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  // Текущий шаг
  const currentStep = steps[activeStep];
  const CurrentStepComponent = currentStep.Content;

  // Закрытие модального окна
  const handleCancel = () => {
    modals.close();
  };

  return (
    <Modal isOpen={true} isCentered onClose={handleCancel}>
      <ModalOverlay />
      {/* Пример обёртки c вашими классами */}
      <ModalContent className={css.content}>
        <ModalBody className={css.body}>
          <CurrentStepComponent
            key={currentStep.key}
            goToNext={goToNext}
            goToPrevious={goToPrevious}
            cancel={handleCancel}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ConfMode;
