import React from 'react';
import { Flex, FlexProps, HStack, Text, Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { Icon } from '../../Icon/Icon';
import { Select } from '../../RadixSelect/Select';
import { SelectItem } from '../../RadixSelect/SelectItem';
import { EventsIndicator } from '../../EventsIndicator/EventsIndicator';
import { getBoardTabs } from '../../../features/Dashboard/constants';
import {
    getUnreadCards,
    getUnansweredCards,
    getMentionedCards,
    getTeamActivityCards,
    getActiveTasksCards,
} from '../../../utils';
import { ApiResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import { setCardsFilterId } from '../../../redux/reducers/page-navigation-state.reducer';
import { colors } from '../../../theme/colors';
import { useGetBoardCards } from '../../../features/Dashboard/queries';
import { WorkspaceSelect } from './WorkspaceSelect/WorkspaceSelect';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { shadows } from '../../../theme/shadows';
import { useNavigateWithFilters } from '../../../hooks/useNavigateWithFilters';

import styles from './styles.module.css';
import { useChats } from '../../../hooks/useChats';

interface SidebarCompanyHeaderProps {
    wrapperProps?: FlexProps;
    showSidebarChatView: boolean;
}

export const SidebarCompanyHeader: React.FC<SidebarCompanyHeaderProps> = ({
    wrapperProps,
    showSidebarChatView,
}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const workspaceId = useGetMatchedWorkspaceId();
    const location = useLocation();
    const navigateWithFilters = useNavigateWithFilters();

    const { data: cardsData } = useGetBoardCards();

    const { filterId, statusId } = useSelector(
        (state: RootStore) => state.pageNavigationState,
    );
    const chats = useChats();

    const teamTelegramIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    const handleBackClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigateWithFilters(`/${workspaceId}`);
    };

    return (
        <Flex
            id="layout-SidebarCompanyHeader"
            w="full"
            pt="6px"
            pb={2}
            justify="space-between"
            align="center"
            {...wrapperProps}
        >
            {!showSidebarChatView ? (
                <WorkspaceSelect />
            ) : (
                <Box
                    as="a"
                    href={`/${workspaceId}`}
                    className={styles.BackwardLink}
                    onClick={handleBackClick}
                >
                    <HStack spacing="4px">
                        <Icon name="arrow-left" width="16px" height="16px" />
                        <Text
                            color="primary"
                            fontSize="13px"
                            lineHeight="20px"
                            fontWeight="500"
                        >
                            Dashboard
                        </Text>
                    </HStack>
                </Box>
            )}

            {showSidebarChatView ? (
                <Select
                    placeholder="Filter by"
                    rootProps={{
                        defaultValue: filterId.toString(),
                        onValueChange: (value) =>
                            dispatch(setCardsFilterId(+value)),
                    }}
                    triggerStyles={{
                        height: '24px',
                        borderWidth: '0px',
                        fontWeight: 500,
                        width: 'auto',
                        color: colors.tertiary,
                        borderRadius: '4px',
                        paddingLeft: '8px',
                        paddingRight: '4px',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        columnGap: '4px',
                    }}
                    triggerClassName="HideIcons HoverTransparentLight"
                    chevronColor={colors.tertiary}
                    chevronSize="16px"
                    contentStyle={{
                        width: '150px',
                        border: `1px solid ${colors.gray[20]}`,
                        boxShadow: shadows.strong.light,
                        backgroundColor: '#fcfcfccc',
                        backdropFilter: 'blur(5px)',
                    }}
                    viewportStyle={{
                        padding: '0px',
                    }}
                >
                    <SelectItem
                        value="1"
                        color="primary"
                        className="HoverTransparentLight"
                        style={{
                            borderBottomWidth: '1px',
                            borderBottomColor: 'gray.15',
                            borderBottomLeftRadius: '0px',
                            borderBottomRightRadius: '0px',
                            cursor: 'pointer',
                        }}
                    >
                        All Chats
                    </SelectItem>

                    {getBoardTabs({
                        unread:
                            getUnreadCards({
                                cards: cardsData || [],
                                chats: chats || [],
                                statusId: statusId,
                            }).length || 0,

                        unanswered:
                            getUnansweredCards({
                                cards: cardsData || [],
                                chats: chats || [],
                                teamTelegramIds:
                                    teamTelegramIds?.value?.telegramUserIds ||
                                    [],
                                statusId: statusId,
                            }).length || 0,
                        activeTasks:
                            getActiveTasksCards({
                                cards: cardsData || [],
                                statusId,
                            }).length || 0,
                        teamActivity:
                            getTeamActivityCards({
                                cards: cardsData || [],
                                statusId,
                            }).length || 0,
                        mentions:
                            getMentionedCards({
                                cards: cardsData || [],
                                statusId: statusId,
                            }).length || 0,
                    })
                        .slice(1)
                        .map((option) => (
                            <SelectItem
                                value={`${option.id}`}
                                key={option.id}
                                color="primary"
                                className="HoverTransparentLight"
                                style={{
                                    position: 'relative',
                                    paddingRight: '8px',
                                    cursor: 'pointer',
                                }}
                            >
                                {option.label}
                                <EventsIndicator
                                    count={option.activity}
                                    wrapperProps={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '4px',
                                        transform: 'translateY(-50%)',
                                        bg: option.activityBgColor,
                                    }}
                                    textProps={{
                                        color: option.activityTextColor,
                                    }}
                                />
                            </SelectItem>
                        ))}
                </Select>
            ) : null}
        </Flex>
    );
};
