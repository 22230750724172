import { Button, Link, Text } from '@chakra-ui/react';
import { Icon } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { BOT_USERNAME } from '../../tg-config';

import css from './index.module.css';

import { useExchangeOtp, useGetOtp } from '@core/api';
import { sleep } from '../../utils';
import { setAuthStatus } from '../../redux/reducers/auth.reducer';
import { useState } from 'react';
import { useTelegramLock } from '../../services/telegram/lockContext';
import { setShowTelegramModal } from '../../redux/reducers/login-flow.reducer';

export const Loader = () => 'Route loader';

export const Action = () => 'Route action';

export const Catch = () => <div>Something went wrong...</div>;

export const Pending = () => <div>Loading...</div>;

export default function LoginPage() {
    const dispatch = useDispatch();

    const lock = useTelegramLock();

    const getOtpMutation = useGetOtp();
    const exchangeOtpMutation = useExchangeOtp();

    const { telegramConnected, userId } = useSelector(
        (state: RootStore) => state.loginFlow,
    );

    const [isCrmLoginLoading, setIsCrmLoginLoading] = useState(false);

    const handleConnectTelegram = () => {
        dispatch(setShowTelegramModal(true));
    };

    const handleCrmLogin = async () => {
        setIsCrmLoginLoading(true);
        const MAX_RETRIES = 5;
        const BASE_DELAY = 100; // 100ms

        for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
            try {
                if (!userId) return;

                const telegramUserId = Number(userId);

                const { otpCode } = await getOtpMutation.mutateAsync({
                    telegramUserId,
                });

                await lock.scheduleBackgroundEvent(async (tg) =>
                    tg?.actions.proxy.openChatByUsername({
                        username: BOT_USERNAME,
                        startParam: otpCode,
                    }),
                );

                await sleep(3000);

                const { token, user } = await exchangeOtpMutation.mutateAsync({
                    otpCode,
                    telegramUserId,
                });

                /**
                 * Request "/exchange" (exchangeOtpMutation)
                 * returning user object not with "current" workspace,
                 * but the first one on workspaces list (legacy-backend bug?).
                 * So setting "user" to store, but later it's overrides
                 * in _redirects.tsx by getCurrentUserQuery to avoid negative side-effects.
                 */
                dispatch(setAuthStatus({ token, user }));
                return; // Success - exit the retry loop
            } catch (error) {
                console.error(`Login attempt ${attempt + 1} failed:`, error);

                if (attempt === MAX_RETRIES - 1) {
                    // Last attempt failed
                    console.error('Max retry attempts reached. Login failed.');
                    break;
                }

                // Exponential backoff with jitter
                const delay =
                    BASE_DELAY * Math.pow(2, attempt) + Math.random() * 1000;
                await sleep(delay);
            }
        }

        setIsCrmLoginLoading(false);
    };

    return (
        <section className={css.layout}>
            <div className={css.content}>
                <Icon
                    name={'logo'}
                    width={'10rem'}
                    height={'2.5rem'}
                    strokeColor={'none'}
                />

                <h2 className={css.title}>Log in</h2>

                <div className={css.controls}>
                    <Text
                        color="primary"
                        fontSize="12px"
                        lineHeight={'18px'}
                        fontWeight={400}
                    >
                        Step 1:
                    </Text>

                    {!telegramConnected ? (
                        <Button
                            isDisabled={telegramConnected}
                            onClick={handleConnectTelegram}
                        >
                            Connect Telegram
                        </Button>
                    ) : (
                        <Button isDisabled={true}>Telegram Connected</Button>
                    )}

                    <Text
                        color="primary"
                        fontSize="12px"
                        lineHeight={'18px'}
                        fontWeight={400}
                    >
                        Step 2:
                    </Text>

                    <Button
                        isDisabled={!telegramConnected}
                        isLoading={isCrmLoginLoading}
                        onClick={handleCrmLogin}
                    >
                        Log in to CRM
                    </Button>
                </div>

                <Text
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'center'}
                    color={'tertiary'}
                >
                    By using DISE, you agree to the{' '}
                    <Link
                        href="https://dise.app/terms"
                        isExternal
                        fontWeight={500}
                    >
                        Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                        href="https://dise.app/privacy"
                        isExternal
                        fontWeight={500}
                    >
                        Data Processing Agreement
                    </Link>
                    .
                </Text>
            </div>
        </section>
    );
}
